/**
 * use REMOTE_URL_FOR_LOCAL_DEV to set the _remoteURL when connecting to local dev server
 * STAGE: 'https://stage.kaios-plus.kaiostech.com'
 * TEST: 'https://test.kaios-plus.kaiostech.com'
 */

const REMOTE_URL_FOR_LOCAL_DEV = 'https://test.kaios-plus.kaiostech.com';
// Since there is no GraphQL service in BETA env, use PROD GraphQL service for it
const REMOTE_URL_FOR_BETA = 'https://kaios-plus.kaiostech.com';

/** A class to handle store is launched by activity.
 */
class ActivityLauncher {
  /**
   * Initializes variables.
   */
  constructor() {
    // turn on _isLocalDev and change REMOTE_URL_FOR_LOCAL_DEV when connecting to local dev server
    this._isLocalDev = false;
    this._remoteURL = this._getRemoteUrl();
    this._apiKey = window.deviceInfos.get('apps.graphql.key');
  }

  /**
   * Get _remoteURL by from settings, specially handle for local dev and BETA env case
   * @return {String} _remoteURL of env
   * @private
   */
  _getRemoteUrl() {
    if (this._isLocalDev) {
      return REMOTE_URL_FOR_LOCAL_DEV;
    }
    const remoteURL = window.deviceInfos.get('apps.serviceCenter.remoteUrl');
    return remoteURL.includes('beta') ? REMOTE_URL_FOR_BETA : remoteURL;
  }

  /**
   * Send an HTTP request using XMLHttpRequest.
   * @param {Object} paramsObj - All parameters you need to send a request.
   * @param {Function} successCb - success callback, default to null.
   * @param {Function} errorCb  - error callback, default to null
   * @public
   */
  httpRequester(paramsObj, successCb = null, errorCb = null) {
    const { url, method, headers, payload } = paramsObj;
    const xhr = new XMLHttpRequest();

    xhr.open(method, url, true);
    // request header
    for (const key in headers) {
      xhr.setRequestHeader(key, headers[key]);
    }
    // response type
    xhr.responseType = 'json';

    xhr.onload = e => {
      const statusCode = e.target.status;
      if (statusCode >= 200 && statusCode < 400) {
        if (successCb) {
          successCb(xhr.response);
        }
      } else if (errorCb) {
        errorCb(`${url}: ${e.target.statusText}`);
      }
    };
    xhr.onerror = () => {
      if (errorCb) {
        errorCb(`${url}: No response from server.`);
      }
    };
    xhr.send(payload);
  }

  /**
   * Find an app by inline activity object via graphql API. (Fully matching)
   * @param {object} inlineActivityObj - The inline activity object.
   * @return {Promise} The app data or null if there is no this app.
   * @public
   */
  getAppFromGraphQL(inlineActivityObj) {
    return new Promise((resolve, reject) => {
      const params = {
        url: `${this._remoteURL}/graphql`,
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain',
          'x-api-key': this._apiKey,
        },
        payload: this._generatePayload(inlineActivityObj),
      };

      /**
       * If search api doesn't work, we will fallback to the original way to
       * open the app detail page.
       */
      this.httpRequester(
        params,
        result => resolve(result.data.getApp[0]),
        error => reject(error)
      );
    });
  }

  /**
   * Generate graphql payload from according to the key and value of inline activity object.
   * @param {Object} inlineActivityObj - The inline activity object.
   * @private
   */
  _generatePayload(inlineActivityObj) {
    const searchCriteria = {
      name: 'name',
      manifestURL: 'manifest_url',
    };
    const [key, value] = Object.entries(inlineActivityObj)[0];
    return `query {
      getApp(${searchCriteria[key]}:"${value}") {
        id,
        name,
        theme,
        version,
        size,
        icons {size_56,size_128},
        create_at,
        default_locale,
        display,
        manifest_url,
        bgs {size_240},
        bundle_id,
        paid,
        supported_platforms,
        developer {name,url},
        locales {en_US {name,subtitle,description}},
        category,
        description,
        type,
        default_locale
      }
    }`;
  }
}

export default ActivityLauncher;
