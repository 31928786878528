import React from 'react';

const DescriptionSection = React.memo(function Desc(props) {
  const setRef = props.setRef;
  const onFocus = props.onFocus;
  const onKeyDown = props.onKeyDown;
  return (
    <div
      className="desc"
      tabIndex="1"
      ref={setRef}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
    >
      <Content {...props} />
    </div>
  );
});

const Content = props => {
  if (props.isUpdatableSysApp && props.isDownloading) {
    return <SysAppUpdatingContent />;
  } else if (props.iscoreUpdated) {
    return <SysAppUpdateDoneContent />;
  } else {
    return (
      <GenericAppContent
        url={props.url}
        desc={props.desc}
        version={props.version}
      />
    );
  }
};

const SysAppUpdatingContent = () => (
  <div className="update-page-desc">
    <h2 data-l10n-id="update-page-desc-updating" />
    <p data-l10n-id="update-page-desc-dont-turn-off" />
  </div>
);

const SysAppUpdateDoneContent = () => (
  <div className="update-page-desc">
    <h2 data-l10n-id="update-success" />
  </div>
);

const GenericAppContent = props => (
  <div className="Page-desc" tabIndex="1">
    {props.url && (
      <div className="website-info">
        <small className="website-url">{props.url}</small>
        <h4 data-l10n-id="website" />
      </div>
    )}
    <p>{props.desc}</p>
    <br />
    {props.version && (
      <p>
        <span data-l10n-id="version" />
        <span>: {props.version}</span>
      </p>
    )}
  </div>
);

export default DescriptionSection;
