import React from 'react';

import 'scss/LikeModal.scss';

const LikeModal = ({ liked }) => {
  const unlikeMsg = navigator.mozL10n.get('unliked-an-app-msg');
  return (
    <div className="like-modal-wrapper">
      {liked ? (
        <div className="like-toast" />
      ) : (
        <div className="unlike-toast">
          <p className="unlike-msg">{unlikeMsg}</p>
        </div>
      )}
    </div>
  );
};

export default LikeModal;
