import React, { Component } from 'react';
import { route } from 'preact-router';
import OptionMenuLib from 'react-option-menu/src/index';

import Account from '@/account';
import { deviceUtils } from '@/device-utils';
import { PATH } from '@/constant';

class OptionMenu extends Component {
  constructor(props) {
    super(props);
    this.optionMenuConfig = this.createOptionMenuConfig();
    this.optionMenuRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { showOptionMenu, toggleOptionMenu } = this.props;
    if (!prevProps.showOptionMenu && showOptionMenu) {
      this.optionMenuRef.current.show({ options: this.optionMenuConfig });
      this.optionMenuRef.current.on('closed', toggleOptionMenu);
    }
  }

  createOptionMenuConfig() {
    const urls = {
      'privacy-policy': 'https://www.kaiostech.com/privacy-policy/',
      'terms-and-services':
        'https://www.kaiostech.com/kaistore-terms-of-service/',
      'need-help': 'https://support.kaiostech.com/support/tickets/new',
    };
    // If settings is true. (Means supported.)
    const accountConfigs = deviceUtils.featureset.settingsPanelSupported
      ? [
          {
            id: Account.version ? 'settings-and-account' : 'settings',
            callback: () => {
              route(PATH.SETTING.URL());
            },
          },
        ]
      : [];
    const urlConfigs = Object.entries(urls).map(([key, val]) => ({
      id: key,
      callback: () => {
        // FIXME: was not working
        window.open(val, '_blank');
      },
    }));
    return [...accountConfigs, ...urlConfigs];
  }

  render() {
    const { showOptionMenu } = this.props;
    return showOptionMenu ? <OptionMenuLib ref={this.optionMenuRef} /> : null;
  }
}

export default OptionMenu;
