import React, { Component } from 'react';
import debounce from 'lodash.debounce';
import AppStore from '@/app-store';
import AppBrick from '@/component/AppBrick';
import UserInterfaceHelper from '@/helper/user-interface-helper';
import analyticsHelper from '@/helper/analytics-helper';

import 'scss/AppListView.scss';

class AppListView extends Component {
  constructor(props) {
    super(props);
    const { categoryCode } = props;
    this.listItemRefs = {};
    this.appListRef = React.createRef();
    this.navDirection = 1;
    this.isFetching = false;
    // This debounced method is to handle user switching category rapidly
    // The API calls will be fired if the user stays at the cate for more than 200 ms
    this.fetchApps = debounce(this.fetchApps.bind(this), 200);
    this.state = {
      apps: this.getApps(),
      currentBrickIndex: UserInterfaceHelper.cateHistory[categoryCode] || 0,
    };
  }

  componentDidMount() {
    window.addEventListener('appstore:change', this.handleStoreChange);
    window.addEventListener('visibilitychange', this.handleVisibilityChange);
    this.fetchApps();
    this.handleFocus();
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentBrickIndex: prevBrickIndex, apps: prevApps } = prevState;
    const { currentBrickIndex, apps } = this.state;
    const { categoryCode: prevCateCode } = prevProps;
    const { categoryCode } = this.props;

    /**
     * handle focus for:
     * 1. after nav by user
     * 2. after handleStoreChange from 0 apps to > 0 apps
     */
    if (prevBrickIndex !== currentBrickIndex) {
      this.handleFocus();
    }

    // handle focus for case that there was app in the cate
    // but fetch app later
    if (
      prevCateCode === categoryCode &&
      prevBrickIndex === currentBrickIndex &&
      prevApps[prevBrickIndex] !== apps[currentBrickIndex]
    ) {
      this.handleFocus();
    }

    if (prevCateCode !== categoryCode) {
      UserInterfaceHelper.cateHistory[prevCateCode] = prevBrickIndex;
      const currentApps = this.getApps();
      const numOfApps = currentApps.length;

      // haven't fetched apps for the category
      if (numOfApps === 0) {
        this.fetchApps();
        this.setState({ apps: currentApps, currentBrickIndex: null });
      } else {
        if (numOfApps < 4) {
          // handle haven't fetched apps for the category with applist api
          // but have gotten apps from combo api. Still need to fetch more to fill the list
          this.fetchApps();
        }
        const currentBrick = UserInterfaceHelper.cateHistory[categoryCode] || 0;
        this.setState(
          { apps: currentApps, currentBrickIndex: currentBrick },
          this.handleFocus
        );
      }
    }
  }

  componentWillUnmount() {
    const { currentBrickIndex } = this.state;
    const { categoryCode } = this.props;
    UserInterfaceHelper.cateHistory[categoryCode] = currentBrickIndex;
    window.removeEventListener('appstore:change', this.handleStoreChange);
  }

  getApps() {
    const { categoryCode } = this.props;
    const apps = AppStore.getAppsByCate(categoryCode);
    this.setListItemRefs(apps);
    return apps;
  }

  setListItemRefs(apps) {
    this.listItemRefs = apps.reduce((acc, app) => {
      acc[app.id] = React.createRef();
      return acc;
    }, {});
  }

  handleStoreChange = () => {
    const { currentBrickIndex } = this.state;
    const currentBrick = currentBrickIndex || 0;

    this.setState({
      apps: this.getApps(),
      currentBrickIndex: currentBrick,
    });
  };

  handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      const { currentBrickIndex, apps } = this.state;
      const app = apps[currentBrickIndex];
      const { id: appId } = app;
      const { categoryCode } = this.props;

      analyticsHelper.saveViewedApp({
        appOrder: currentBrickIndex,
        appId,
        categoryCode,
      });
    }
  };

  handleKeydown = e => {
    switch (e.key) {
      case 'ArrowUp':
        this._nav(-1);
        e.stopPropagation();
        break;
      case 'ArrowDown':
        this._nav(1);
        e.stopPropagation();
        break;
      default:
        // do nothing, pass to parent.
        break;
    }
  };

  handleFocus = () => {
    const { currentBrickIndex, apps } = this.state;
    if (apps.length === 0) {
      // still have to focus on something or the keydown will freeze
      this.appListRef.current.focus();
    } else {
      const app = apps[currentBrickIndex];
      const { id: appId } = app;
      const brick = this.listItemRefs[appId].current;
      const { categoryCode } = this.props;
      if (brick) {
        brick.handleFocus(this.navDirection);
        analyticsHelper.saveViewedApp({
          appOrder: currentBrickIndex,
          appId,
          categoryCode,
        });
      }
    }
  };

  fetchApps() {
    const { categoryCode } = this.props;
    return AppStore.fetchAppListByCate(categoryCode);
  }

  _nav(move) {
    const { currentBrickIndex, apps } = this.state;
    const numberOfApps = apps.length;
    const nextIndex = currentBrickIndex + move;
    this.navDirection = move;

    // Try fetching more apps if there are only 5 more apps left in the list
    if (numberOfApps - nextIndex <= 5 && move === 1 && !this.isFetching) {
      // ugly isFetching flag to prevent firing multiple API requests
      this.isFetching = true;
      const { categoryCode } = this.props;
      AppStore.fetchAppListByCate(categoryCode).then(() => {
        this.isFetching = false;
      });
    }

    if (nextIndex >= 0 && nextIndex < numberOfApps) {
      this.setState({ currentBrickIndex: nextIndex });
    }
  }

  render() {
    const { apps } = this.state;
    return (
      <div
        className="AppListView"
        tabIndex="1"
        onKeyDown={this.handleKeydown}
        onFocus={this.handleFocus}
        ref={this.appListRef}
      >
        <div className="container">
          {apps.length > 0 ? (
            apps.map((app, index) => (
              <AppBrick
                key={app.id}
                ref={this.listItemRefs[app.id]}
                data-id={app.id}
                appOrder={index}
                app={app.brickInfo}
                mozAPP={app.mozAPP}
                // FIXME: weird logic, but Robin forgot why
                hasProgressBar={true}
              />
            ))
          ) : (
            <div className="loader" />
          )}
        </div>
      </div>
    );
  }
}

export default AppListView;
