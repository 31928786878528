import { deviceUtils } from '../device-utils';
import {
  validSettingData,
  validSettingKey,
} from 'kaistore-post-messenger/lib/constants';
import { MessageSender } from 'web-message-helper';
import { RequestSettingCommand } from 'kaistore-post-messenger/src/commands';

/** A class to handle rich content setting. When the setting value matches with
 * current network status, background image of the brick would be loaded upon
 * user focusing under AppListView.
 */
class RichContentHelper {
  /**
   * Initializes variables.
   */
  constructor() {
    /**
     * Defines if rich content is enable.
     * @type Boolean
     * @private
     */
    this._enable = false;
    /**
     * Defines the true setting value.
     * @type String
     * @private
     */
    this._settingValue = null;
  }

  /**
   * Sets the true setting value.
   * @type String
   */
  set settingValue(value) {
    this._settingValue = value;
  }

  /**
   * Check if rich content should be enabled by comparing setting value and
   * current network status.
   * @type {function}
   * @param {String} settingValue - The setting value of rich content.
   * @return {Boolean}
   * @private
   */
  _checkEnable(settingValue) {
    let enable = false;
    switch (settingValue) {
      case 'wifi-only':
        enable = deviceUtils.information.connection.type === 'wifi';
        break;
      case 'data-enable':
        enable = true;
        break;
      default:
        enable = false;
    }
    return enable;
  }

  /**
   * Requests setting value from client and calls _checkEnable() to check if
   * rich content should be avaliable.
   * @type {Boolean}
   */
  get enable() {
    if (this._settingValue === null) {
      const key = validSettingKey.RICH_CONTENT;
      MessageSender.send(
        new RequestSettingCommand({
          detail: {
            key: key,
          },
        }),
        (success, result) => {
          this.settingValue =
            success && result.value
              ? result.value
              : validSettingData[key].default;
          return this._checkEnable(this._settingValue);
        }
      );
    } else {
      return this._checkEnable(this._settingValue);
    }
  }
}

export default (RichContentHelper = new RichContentHelper());
