import React, { Component } from 'react';
import { route } from 'preact-router';
import {
  RemoteUIReadyCommand,
  ShowNotificationCommand,
} from 'kaistore-post-messenger/src/commands';
import { Notification } from 'kaistore-post-messenger/src/models';
import { MessageSender } from 'web-message-helper';
import ReactDialog from 'react-dialog/src/index';
import SoftKeyStore from 'soft-key-store';

import AppBrick from '@/component/AppBrick';
import {
  // LikeModal,
  DescriptionSection,
} from './component';

import { PATH } from '@/constant';
import Account from '@/account';
import IconConverter from '@/icon-converter';
import AppStore from '@/app-store';
import { utils } from '@/utils';
import { deviceUtils } from '@/device-utils';

import { openWebsite, pinWebsite, unPinWebsite } from '@/helper/bookmark-utils';
import UserInterfaceHelper from '@/helper/user-interface-helper';
import PaymentHelper from '@/helper/payment-helper';
import { loggerHelper } from '@/helper/logger-helper';
import analyticsHelper from '@/helper/analytics-helper';

import 'scss/PagePanel.scss';

const _ = navigator.mozL10n;

class PagePanel extends Component {
  constructor(props) {
    super(props);
    this.appNotFound = false;
    this.autoInstallHandled = false;

    this.setCurrentApp(true);

    if (this.appNotFound) {
      return;
    }
    this.paymentHelper = new PaymentHelper();
    this.iconConverter = IconConverter;
    this.refs = {};
    this.bookmarkSupported = deviceUtils.featureset.bookmarkDBSupported;
    this.isDisablingApp = false;
    window.addEventListener('appstore:change', this.handleStoreChange);
  }

  componentDidMount() {
    if (this.appNotFound) {
      return;
    }

    if (UserInterfaceHelper.UIReadyCommandSent === false) {
      const timestamp = new Date().getTime();

      performance.mark('REMOTE_PAGE_RENDERING_END');
      /**
       * Inform client store that remote UI is ready, and shift page from
       * loading page to remote iframe.
       */
      MessageSender.send(
        new RemoteUIReadyCommand({
          detail: {
            timestamp,
            isInlineActivity: true,
          },
        }),
        () => {
          UserInterfaceHelper.UIReadyCommandSent = true;
          this.focus();
          utils.performanceLogs(timestamp);
        }
      );
    } else {
      this.focus();
    }

    this.refs.dialog.on('closed', () => {
      this.hideDialog();
      // To avoid `Backspace` mess up softkey, set focus back to 'page'.
      this.focus();
    });
    this.sendUserActionData('store_app_view_done');
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.appNotFound) {
      return;
    }
    if (
      this.props.matches.id !== prevProps.matches.id ||
      this.props.matches.manifest !== prevProps.matches.manifest
    ) {
      this.autoInstallHandled = false;
      this.setCurrentApp();
    }

    if (
      this.props.matches.id === prevProps.matches.id &&
      this.props.matches.t !== prevProps.matches.t &&
      this.props.matches.autoDownload === 'true'
    ) {
      this.autoInstallHandled = false;
    }

    if (this.props.matches.t !== prevProps.matches.t) {
      // another activity is called when user is still in Page
      this.focus();
    }

    if (prevState.dialog !== this.state.dialog) {
      if (this.state.dialog) {
        this.refs.dialog.show();
      } else {
        this.refs.dialog.hide();
      }
    }

    const { batchUpdating } = this.props.matches;
    const isCoreBatchUpdate =
      this.state.app.state.coreUpdated && batchUpdating === 'true';
    if (isCoreBatchUpdate) {
      const nextManifest = AppStore.popNextUpdatePendingSystemApp;
      if (nextManifest) {
        route(PATH.PAGE.URL({ manifest: nextManifest, batchUpdating: true }));
      } else {
        // last core app from AppStore.updateAll
        AppStore.rebootIfNeeded();
      }
    }

    if (!batchUpdating && this.state.app.state.coreUpdated) {
      AppStore.rebootIfNeeded();
    }

    if (
      batchUpdating === 'true' &&
      !this.state.app.state.coreUpdated &&
      !this.state.app.isCoreUpdating
    ) {
      this.handleAutoDownload();
    }

    if (this.isDisablingApp && !this.state.app.isInstalled) {
      this.isDisablingApp = false;
    }

    this.updateSoftKey();
  }

  componentWillUnmount() {
    if (this.element) {
      this.element = undefined;
    }
    window.removeEventListener('appstore:change', this.handleStoreChange);
  }

  get shouldHandleAutoDownload() {
    const { paid } = this.state.app.info;
    const { autoDownload, batchUpdating } = this.props.matches;
    return (
      this.autoInstallHandled === false &&
      paid === false &&
      (autoDownload === 'true' || batchUpdating === 'true')
    );
  }

  setCurrentApp(initializing = false) {
    const { appOrder, adId, entry, id, manifest, name } = this.props.matches;
    // manifest or name only exist when the page is entered by mozActivity
    let app = null;
    if (id !== '') {
      app = AppStore.findItemById(id);
    } else if (manifest) {
      app = AppStore.findAppByManifest(decodeURIComponent(manifest));
    } else if (name) {
      app = AppStore.findAppByName(name);
    }

    if (app === null || typeof app === 'undefined') {
      this.appNotFound = true;
      route(PATH.APPS.URL());
      this.props.onAppNotFound();
      return;
    }

    if (initializing) {
      this.state = {
        dialog: false,
        dialogOptions: {},
        app,
      };
    } else {
      this.setState({ app });
    }

    if (app.isInRemoteList) {
      loggerHelper.postAppViewData(app, appOrder, adId);
    }
    this.sendUserActionData('store_app_view_init', {
      appOrder,
      entry,
    });
  }

  handleStoreChange = event => {
    // cannot get id from matches if the page is entered via activity
    const { manifestURL } = this.state.app;
    if (event.detail && event.detail.manifestURL !== manifestURL) {
      return;
    }

    const app = AppStore.findAppByManifest(manifestURL);
    if (!app) {
      this.exitPage();
      return;
    }

    this.setState({
      app,
    });
  };

  handleKeydown = e => {
    const { app } = this.state;
    if (app.isCoreUpdating) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    switch (e.key) {
      case 'SoftRight':
        if (app.isBookmark) {
          this.showUnPinDialog();
        } else {
          this.showUnInstallDialog();
        }
        break;
      case 'SoftLeft':
        if (app.isBookmark) {
          pinWebsite(app);
        }
        break;
      case 'EndCall':
        e.preventDefault();
        e.stopPropagation();
        this.exitPage(e);
        break;
      case 'BrowserBack':
      case 'Backspace':
      case 'Escape':
        e.stopPropagation();
        this.exitPage(e);
        break;
      case 'Accept':
      case 'Enter':
        if (app.isBookmark) {
          openWebsite(app);
        } else {
          this.handleCSK();
        }
        break;
      default:
        // do nothing.
        break;
    }
  };

  handleAutoDownload = () => {
    const { app } = this.state;
    if (app.isCoreUpdating) {
      return;
    }
    // bind to onFocus to ensure the user has navigated back from account page
    if (this.shouldHandleAutoDownload) {
      this.autoInstallHandled = true;
      if (app.isBookmark) {
        openWebsite(app);
      } else {
        this.handleCSK();
      }
    }
  };

  hideDialog = () => {
    this.setState({
      dialog: false,
    });
  };

  disableAPP = () => {
    const { app } = this.state;
    const { isInstalled, mozAPP } = app;

    if (mozAPP === null) {
      return;
    }
    // If removable is false, please don't remove it.
    if (isInstalled && mozAPP.removable !== false) {
      this.isDisablingApp = true;
      app.uninstall();
    }
  };

  exitPage(e) {
    const { disposition, entry } = this.props.matches;
    const isEmptyApp = Object.keys(this.state.app.info.localized).length === 0;

    if (isEmptyApp === false) {
      this.state.app.updateState({ coreUpdated: false });
    }

    if (disposition !== 'inline') {
      switch (entry) {
        case 'search':
          route(PATH.SEARCH.URL());
          break;
        case 'apps':
        default:
          route(PATH.APPS.URL());
          break;
      }
      if (e) {
        e.preventDefault();
      }
    }
  }

  toggleLike() {
    // const { app } = this.state;
    // app.updateState({ liked: !app.state.liked });
  }

  handleCSK() {
    const { app } = this.state;
    const { manifestURL, isInstalled, info } = app;
    const { paid, productId, unitPrice } = info;
    const { updatable, downloading, coreUpdated } = app.state;

    if (coreUpdated) {
      this.exitPage();
      return;
    }

    if (downloading) {
      app.cancelDownload();
      return;
    }

    if (isInstalled && !updatable) {
      app.launch();
    } else if (isInstalled && updatable) {
      // XXX TODO: update App with purchased account.
      app.update().catch(() => {
        this.handleDownloadFailed(app);
      });
    } else if (!isInstalled && !updatable) {
      if (paid) {
        if (!unitPrice) {
          this.showPaymentUnavailableDialog();
          return;
        }
        if (!productId) {
          console.error(`Paid App without productId`);
          return;
        }

        const loginRequired = Account.version && Account.token === null;
        if (loginRequired) {
          // Before enable APPs, users need to login their account first.
          AppStore.preparedDownloadApp = manifestURL;
          Account.openAccount('fxa')
            .then(() => {
              // If user closes settings activity and login success
              if (Account.token) {
                this.handleCSK();
              }
            })
            .catch(evt => {
              console.error(evt);
            });
          return;
        }

        this.paymentHelper
          .isProductPurchased(productId)
          .then(product => {
            const transactionId = product.transaction_id;
            return this.paymentHelper.getReceiptsByTransactionId(transactionId);
          })
          .then(result => {
            const jwt = {
              receiptToken: result.receipt_token,
            };
            // TODO: should NOT change state directly.
            this.state.app.purchased = true;
            this.startDownload(app, jwt);
          })
          .catch(() => {
            // Enter payment process, pay the money to get receipt_token
            return this.paymentHelper.pay({
              id: productId,
              name: info.localized.name,
            });
          })
          .then(result => {
            if (!result) {
              return;
            }
            // TODO: should NOT change state directly.
            this.state.app.purchased = true;
            this.startDownload(app, result);
          })
          .catch(error => {
            console.error('Failed payment process: ', error);
          });
      } else {
        this.startDownload(app);
      }
    }
  }

  startDownload(app, jwt = null) {
    const { isHosted } = app;
    const { pixel } = this.props.matches;

    app
      .install(jwt)
      .then(() => {
        if (isHosted) {
          this.sendInstallHostedLog();
        }
        if (pixel) {
          this.sendAdTrackingPixel(pixel);
        }
      })
      .catch(error => {
        this.handleDownloadFailed(app);
        if (isHosted) {
          this.sendInstallHostedLog(error);
        }
      });

    this.sendUserActionData('store_app_install_init');
  }

  handleDownloadFailed(app) {
    // It's workaround for bug-53987: Store shouldn't be the notification sender.
    const msg = 'device-issue-detail';
    const msgBody = _.get(msg);
    const options = {
      icon: this.iconConverter.getBase64('download'),
      body: msgBody,
    };
    const title = 'device-issue-title';

    app.handleDownloadError();
    this.showNotification(title, options);
  }

  focus() {
    this.updateSoftKey();
    this.descriptionElement.focus();
  }

  showNotification(titleL10nId, options) {
    const title = navigator.mozL10n.get(titleL10nId);
    const command = new ShowNotificationCommand({
      detail: new Notification({
        title,
        options,
      }),
    });
    MessageSender.send(command);
  }

  updateSoftKey() {
    const { app } = this.state;
    SoftKeyStore.register(app.softKeyOption, this.descriptionElement);
  }

  showPaymentUnavailableDialog() {
    const dialogOptions = {
      translated: true,
      header: _.get('payment-not-available'),
      type: 'alert',
      ok: 'ok',
      content: _.get('payment-not-available-content'),
    };
    this.showDialog(dialogOptions);
  }

  showUnInstallDialog() {
    const { app } = this.state;
    const { mozAPP, isInstalled, info } = app;
    if (
      mozAPP === null ||
      mozAPP.removable === false ||
      !isInstalled ||
      this.isDisablingApp
    ) {
      return;
    }

    const content = _.get('uninstall-msg-with-name', {
      appName: info.localized.name,
    });

    this.showConfirmDialog(content, 'uninstall', this.disableAPP);
  }

  sendAdTrackingPixel(pixel) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', pixel);
    xhr.onload = e => {
      const statusCode = e.target.status;
      if (statusCode < 200 || statusCode >= 400) {
        console.error('fail to send ad tracking');
      }
    };
    xhr.onerror = err => {
      console.error('fail to send ad tracking. err: ', err);
    };
    xhr.send();
  }

  showUnPinDialog() {
    const { app } = this.state;
    const { state, info } = app;
    const { pinned } = state;
    if (!pinned || !this.bookmarkSupported) {
      return;
    }

    const content = _.get('unpin-msg-with-name', {
      appName: info.localized.name,
    });

    this.showConfirmDialog(content, 'unpin', () => {
      unPinWebsite(app);
    });
  }

  showConfirmDialog(content, okl10nId, onOK) {
    const dialogOptions = {
      translated: true,
      header: _.get('confirmation'),
      type: 'confirm',
      ok: okl10nId,
      content,
      onOk: onOK,
    };

    this.showDialog(dialogOptions);
  }

  showDialog(options) {
    this.setState({
      dialog: true,
      dialogOptions: options,
    });
  }

  sendInstallHostedLog(error = null) {
    const { app } = this.state;
    const { info } = app;
    const data = {
      app_id: app.id,
      app_name: info.localized.name,
    };
    loggerHelper.postAppInstallData(data, error);
  }

  sendUserActionData(
    event_name = '',
    detail = { entry: null, appOrder: null }
  ) {
    const logInfo = {
      event_name,
      app_id: this.state.app.id,
      app_version: this.state.app.info.version,
    };

    if (detail.entry && detail.entry === 'search') {
      logInfo.referral_type = 'store_search';
      logInfo.referral_store_search_result_index = detail.appOrder;
    }

    analyticsHelper.postAppViewEvent(logInfo);
  }

  render() {
    if (this.appNotFound) {
      return null;
    }
    const { app } = this.state;
    const { info } = app;
    // const showModal = app.state.liked; // enable it when the backend is ready.

    const desc = info.localized.description;
    const url = app.isBookmark ? app.info.url || app.manifestURL : null;
    return (
      <div
        className="PagePanel active"
        ref={element => {
          this.element = element;
        }}
      >
        <AppBrick
          key={app.id}
          data-id={app.id}
          data-type={info.type}
          app={app.brickInfo}
          mozAPP={app.mozAPP}
          isActivateAsPage={true}
          hasProgressBar={(app.core && app.state.downloading) || !app.core}
          core={app.core}
        />
        {/* enable it when the backend is ready. */}
        {/* {showModal && <LikeModal liked={app.state.liked} />} */}
        <DescriptionSection
          isUpdatableSysApp={app.core && app.state.updatable}
          isDownloading={app.state.downloading}
          iscoreUpdated={app.state.coreUpdated}
          setRef={element => {
            this.descriptionElement = element;
          }}
          onFocus={this.handleAutoDownload}
          onKeyDown={this.handleKeydown}
          url={url}
          desc={desc}
          version={info.version}
        />
        {app.core &&
          app.state.updatable &&
          !app.state.downloading && (
            <div className="update-btn-wrapper">
              <div className="btn">
                <div
                  data-l10n-id={
                    app.isRebootNeeded ? 'update-and-restart' : 'update'
                  }
                />
              </div>
            </div>
          )}
        <div id="dialog-root" className={this.state.dialog ? '' : 'hidden'}>
          <ReactDialog
            ref={dialog => {
              this.refs.dialog = dialog;
            }}
            {...this.state.dialogOptions}
          />
        </div>
      </div>
    );
  }
}

export default PagePanel;
