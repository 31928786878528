import regeneratorRuntime from 'regenerator-runtime';
// Shared Libs
import '../shared/js/l10n';
import '../shared/js/async_storage';
// Libs
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Router, { route } from 'preact-router';
import { MessageListener, MessageSender } from 'web-message-helper';
import {
  RequestExitCommand,
  RequestInstalledAppsCommand,
  ShowToastCommand,
  SyncLoadingProgressCommand,
} from 'kaistore-post-messenger/src/commands';
import { Toast } from 'kaistore-post-messenger/src/models';
import { tokenType } from 'kaistore-post-messenger/lib/constants';

import Account from '@/account';
import { APP_ORIGIN, PATH, SPECIAL_CATE_CODE } from '@/constant';
import { utils } from '@/utils';
import AppStore from '@/app-store';
import { mozActivityManager } from '@/mozactivity-manager';
import { deviceUtils } from '@/device-utils';
import { receiveMessage } from '@/helper/message-receiver';
import ActivityLauncher from '@/helper/activity-launcher';
import analyticsHelper from '@/helper/analytics-helper';
import { loggerHelper } from '@/helper/logger-helper';
import UserInterfaceHelper, {
  setThemeColor,
} from '@/helper/user-interface-helper';

import '@/lib/debug-error.js';

// Component
import ReactSoftKey from 'react-soft-key';
import ReactDialog from 'react-dialog/src/index';
// panels
import LoadingPanel from '@/panel/LoadingPanel';
import PagePanel from '@/panel/PagePanel';
import AppsPanel from '@/panel/AppsPanel';
import SearchPanel from '@/panel/SearchPanel';
import SettingsPanel from '@/panel/SettingsPanel';

// CSS
import 'react-soft-key/assets/index.scss';
import '../scss/app.scss';
import '../scss/largetext.scss';

import '../shared/elements/gaia-theme/gaia-theme.css';
import '../shared/elements/gaia_tabs/style.css';
import '../shared/elements/gaia-theme/gaia-font.css';
import '../shared/elements/gaia-icons/gaia-icons.css';

const KEY_SERVER_TIME_OFFSET = 'hawk-request-server-time-offset';

class App extends Component {
  constructor() {
    super();
    window.app = this;
    utils.remoteStartTime = new Date().getTime();
    performance.mark('REMOTE_STORE');
    performance.mark('REMOTE_PAGE_RENDERING_START');
    this.entryURL = null;
    this.currentRoute = null;
    this.language = 'en-US';
    MessageSender.remoteReady = true;
    MessageSender.postFullQueue();
    this.syncLoadingProgress(70);
    this.dialogQueue = [];
    // get default state.
    this.state = this.getInitialState();
    // save token information.
    this.tokenInfo = {
      assertion: null,
      type: tokenType.RESTRICTED,
      status: false,
      refreshed: false,
    };
    this.refs = {};
    // init KaiOS account
    Account.init();
    this.startMessengers();
    performance.mark('REQUEST_DEVICE_START');
    // FIXME: side effects in the constructor
    deviceUtils.requestInfo().then(() => {
      performance.mark('REQUEST_DEVICE_END');
      this.boot();
    });
  }

  getInitialState() {
    const initialState = {
      noAvailableAPP: false,
      dialog: false,
      dialogOptions: {},
      plusInfo: {
        version: '2.4', // default
      },
      readyToRoute: false, // true when all prerequisites have finished
      lastAPIFetched: false, // true when apps/combo and /apps have fetched
      isViewingSetting: false,
    };
    return initialState;
  }

  resetState() {
    this.prepareDownload = AppStore.preparedDownloadApp;
    AppStore.resetStore();
    this.setState(this.getInitialState());
  }

  // FIXME: should separate it to boot() and reboot()
  boot = event => {
    const isReboot = !(typeof event === 'undefined');
    if (isReboot) {
      // resetState
      this.resetState();
      route(PATH.LOADING.URL());
      // reset appStore.
      AppStore.resetStore();
      deviceUtils.requestInfo().then(() => {
        this.startRemoteServices();
      });
    } else {
      this.initPreparation();
    }
  };

  initPreparation() {
    // Listen relative events
    this.startEventListener();

    // Get the time offset from indexedDB if it exists
    asyncStorage.getItem(KEY_SERVER_TIME_OFFSET, result => {
      window.serverTimeOffset = result;
    });

    this.fetchDataAndGenerateUI();
  }

  fetchDataAndGenerateUI = () => {
    // FIXME: setState() in constructor
    this.resetState();
    // show loading panel.
    route(PATH.LOADING.URL());
    // show no network UI and early return here.
    if (!navigator.onLine) {
      // reset state to make sure UI works correctly.
      this.setMsg('no-internet');
      console.error('no network');
      return;
    }
    UserInterfaceHelper.UIReadyCommandSent = false;
    // Start to request things from server,
    // such as token, app-list, categories and service worker.
    this.startRemoteServices();
  };

  startRemoteServices(refreshToken = false) {
    const searchParams = new URLSearchParams(this.entryURL);
    const disposition = searchParams.get('disposition');
    const manifestURL = searchParams.get('manifest');
    const name = searchParams.get('name');
    this.language = searchParams.get('locale');

    // init after window.deviceInfos available
    analyticsHelper.init(this.entryURL);
    loggerHelper.init();
    // pass the locale for init AppListHelper
    AppStore.initHelpers(this.language);

    performance.mark('REQUEST_TOKEN_START');
    Account.requestToken(refreshToken)
      .then(detail => {
        const { token, type } = detail;
        // FIXME: tokenInfo could be slimmed down
        this.tokenInfo = {
          assertion: token,
          type,
          status: true,
          refreshed: refreshToken,
        };
        performance.mark('REQUEST_TOKEN_END');
      })
      .then(() => {
        // need token to get purchasedApps
        AppStore.initPurchasedApps();
      })
      .then(() => {
        /**
         * 1. inline activity ("inline-open-page", "inline-open-by-name"):
         *    Only need to get the intended app (ideally through graphQL) since it will return to caller when back,
         *    no need to get combo / applist unless graphQL failed
         * 2. window activity ("open-page", "open-by-name", or "open-deeplink" w/ or w/o manifestURL):
         *    "open-deeplink" with no manifestURL: Just launch store with combo api
         *    TODO: Others: Need to get the intended app (ideally from graphQL, get applist if failed), and combo (ideally from cache if available)
         *    Current solution: fire API request to get combo and applist (just like the old way)
         */
        if (disposition === 'inline') {
          // "inline-open-page" or "inline-open-by-name"
          const inlineActivityObj = {};
          if (manifestURL) inlineActivityObj.manifestURL = manifestURL;
          if (name) inlineActivityObj.name = name;
          this.preparePageInfo(inlineActivityObj);
        } else if (disposition === 'window') {
          if (name || manifestURL) {
            // launch store via "open-page", "open-by-name", or "open-deeplink" and specifying apps={manifestURL}
            this.fetchAppsAndCategories();
          } else {
            // launch store via "open-deeplink" without specifying manifestURL
            this.fetchComboAPI();
          }
        } else {
          // launch store normally
          this.fetchComboAPI();
        }
      });
  }

  preparePageInfo(inlineActivityObj) {
    this.syncLoadingProgress(85);
    const activityLauncher = new ActivityLauncher();
    activityLauncher
      .getAppFromGraphQL(inlineActivityObj)
      .then(app => {
        this.syncLoadingProgress(99);

        app.mozAPP = null;
        app.downloading = false;
        app.installed = false;
        app.updatable = false;
        app.progress = 0;

        AppStore.initRemoteApps([app]);
        this.isInstalledApp(app.manifest_url).then(() => {
          /*
           * For inline activity, when graghql is fetched we should set it
           * to true as well.
           */
          this.setState({ lastAPIFetched: true });
          this.requestLaunchList();
        });
      })
      .catch(error => {
        console.error(error);
        /*
         * Corner Case Handling
         * Go to app list when the app is not found. In this case, the loading
         * time of first view will be longer. User will stuck at 85% for a
         * while since we are fetching all apps and categories.
         */
        this.fetchAppsAndCategories();
      });
  }

  isInstalledApp(manifestURL) {
    return new Promise(resolve => {
      performance.mark('REQUEST_INSTALLED_START');
      MessageSender.send(
        new RequestInstalledAppsCommand(),
        (success, detail) => {
          performance.mark('REQUEST_INSTALLED_END');
          if (success) {
            AppStore.syncInstalledApps(detail);
            resolve(detail[manifestURL]);
          }
          resolve(null);
        }
      );
    });
  }

  stop() {
    this.stopEventListener();
  }

  startMessengers() {
    MessageSender.validMessageOrigins = [APP_ORIGIN];
    MessageSender.targetOrigin = APP_ORIGIN;
    MessageSender.start();
    MessageListener.start(receiveMessage);
  }

  startEventListener() {
    window.addEventListener('keydown', this.keydownHandler);
    window.addEventListener('online', this.fetchDataAndGenerateUI);
    window.addEventListener('offline', this.fetchDataAndGenerateUI);
    window.addEventListener('account:login', this.fetchDataAndGenerateUI);
    window.addEventListener('account:logout', this.fetchDataAndGenerateUI);
    window.addEventListener('reboot-needed', this.boot);

    window.addEventListener(
      'hawkrequester:offsetchange',
      this.handleHawkOffsetChange
    );

    UserInterfaceHelper.startListeners();
  }

  keydownHandler = event => {
    const { key } = event;

    switch (key) {
      case 'BrowserBack':
      case 'Backspace':
      case 'Escape':
        analyticsHelper.postStoreCloseEvent({
          event_name: 'store_close',
        });
        // Caller app launch store via deeplink.
        if (mozActivityManager.shouldPostResultToCaller) {
          event.preventDefault();
          mozActivityManager.shouldPostResultToCaller = false;
        }
        MessageSender.send(new RequestExitCommand());
        break;
      default:
        break;
    }
  };

  syncLoadingProgress(percentage) {
    const command = new SyncLoadingProgressCommand({
      detail: { percentage },
    });
    MessageSender.send(command);
  }

  showRestartDialog = () => {
    const restartDialogOptions = {
      header: 'no-price-header',
      type: 'alert',
      ok: 'ok',
      content: 'restart-phone-msg',
      onOk: this.hideDialog,
    };
    this.showDialog(restartDialogOptions);
  };

  fetchComboAPI() {
    performance.mark('FETCH_APPS_CAT_START');
    const { assertion } = this.tokenInfo;
    AppStore.fetchComboList(!!assertion)
      .then(() => {
        this.syncLoadingProgress(85);
        this.prepareApps();
        this.setState({
          lastAPIFetched: true,
        });
      })
      .catch(error => {
        // only retry once with new token if entrance API failed
        if (error.status !== 401 || this.tokenInfo.refreshed) {
          this.serverError(error);
        } else {
          this.startRemoteServices(true);
        }
      });
  }

  fetchAppsAndCategories() {
    // fetch latest app list
    performance.mark('FETCH_APPS_CAT_START');
    const { assertion } = this.tokenInfo;
    if (assertion) {
      AppStore.fetchComboList(!!assertion)
        .then(() => {
          this.syncLoadingProgress(85);
          this.prepareApps();
        })
        .catch(error => {
          // only retry once with new token if entrance API failed
          if (error.status !== 401 || this.tokenInfo.refreshed) {
            this.serverError(error);
          } else {
            this.startRemoteServices(true);
          }
        });
      AppStore.fetchAppListByCate(SPECIAL_CATE_CODE.ALL).then(() => {
        this.setState({
          lastAPIFetched: true,
        });
      });
    } else {
      console.error('No restricted token for fetching app list.');
    }
  }

  setMsg(messageL10nId, messageL10nArgs = {}) {
    if (document.visibilityState === 'hidden') {
      // Avoid to show toast when app is in background.
      return;
    }
    const command = new ShowToastCommand({
      detail: new Toast({
        messageL10nId: messageL10nId,
        messageL10nArgs: messageL10nArgs,
      }),
    });
    MessageSender.send(command);
  }

  redirectAppPage(manifestURL) {
    let activeApp = null;

    if (manifestURL) {
      activeApp = AppStore.findAppByManifest(manifestURL);
      if (activeApp) {
        route(PATH.PAGE.URL({ id: activeApp.id, autoDownload: true }));
      } else {
        this.handleAppNotFound();
      }
    }
  }

  prepareApps() {
    // In order to reduce render times, so fetch setting here.
    let isSupportedSettingsPanel = window.deviceInfos.get(
      'apps.serviceCenter.settingsEnabled'
    );

    performance.mark('REQUEST_INSTALLED_START');
    MessageSender.send(new RequestInstalledAppsCommand(), (success, detail) => {
      if (success) {
        const installedApps = detail;
        AppStore.syncInstalledApps(installedApps);
        AppStore.syncBookmarks().then(result => {
          deviceUtils.featureset = {
            bookmarkDBSupported: result.bookmarkDBSupported,
            settingsPanelSupported: isSupportedSettingsPanel,
          };
          this.requestLaunchList();
          // add eventListener after app list is ready
          // to avoid app is not found
          window.addEventListener('task-queue-updated', () => {
            let mozActivityTask = mozActivityManager.task;
            if (mozActivityTask) {
              mozActivityManager.handleTask(
                mozActivityTask,
                this.handleAppNotFound
              );
            }
          });
        });
        this.syncLoadingProgress(99);
        performance.mark('REQUEST_INSTALLED_END');
      }
    });
  }

  handleHawkOffsetChange = event => {
    let offset = event.detail.current;
    window.serverTimeOffset = offset;
    asyncStorage.setItem(KEY_SERVER_TIME_OFFSET, offset);
  };

  handleAppNotFound = () => {
    // Launch app list first and then launch alert to notice user
    // can't find app.
    this.showNotFoundDialog();
  };

  showNotFoundDialog = () => {
    const notFoundDialogOptions = {
      header: 'not-found-header',
      type: 'alert',
      ok: 'ok',
      content: 'not-found-msg',
      onOk: this.hideDialog,
    };
    this.showDialog(notFoundDialogOptions);
  };

  serverError(error) {
    const { errorMsgId } = error;
    if (errorMsgId) {
      if (errorMsgId === 'no-app-available') {
        this.setState({ noAvailableAPP: true });
      } else {
        this.setMsg(errorMsgId);
      }
    }
  }

  stopEventListener() {
    //TODO
  }

  componentDidUpdate(prevProps, prevState) {
    const searchParams = new URLSearchParams(this.entryURL);
    const needPostResult = searchParams.get('postResult') === 'true';

    if (prevState.dialog !== this.state.dialog) {
      if (this.state.dialog) {
        this.onDialogInShowState();
      } else {
        this.onDialogInHideState();
      }
    }

    const appDetailAsEntrance = this.entryURL.startsWith('/app');
    if (prevState.readyToRoute === false && this.state.readyToRoute === true) {
      if (!this.prepareDownload && !appDetailAsEntrance) {
        mozActivityManager.shouldPostResultToCaller = needPostResult;
        route(this.entryURL);
        if (AppStore.kaipayUpdated) {
          this.showRestartDialog();
        }
      }
    }
    if (
      prevState.lastAPIFetched === false &&
      this.state.lastAPIFetched === true
    ) {
      if (this.prepareDownload) {
        this.redirectAppPage(this.prepareDownload);
      } else if (appDetailAsEntrance) {
        mozActivityManager.shouldPostResultToCaller = needPostResult;
        route(this.entryURL);
      }
    }

    if (prevState.isViewingSetting !== this.state.isViewingSetting) {
      setThemeColor(this.state.isViewingSetting);
    }
  }

  requestLaunchList() {
    this.setState({ readyToRoute: true });
  }

  showDialog(options) {
    if (
      this.state.dialog === true ||
      this.currentRoute === PATH.LOADING.URL()
    ) {
      this.dialogQueue.push(options);
    } else {
      this.setState({
        dialog: true,
        dialogOptions: options,
      });
      UserInterfaceHelper.UIState.dialog = true;
    }
  }

  hideDialog = callback => {
    UserInterfaceHelper.UIState.dialog = false;
    this.setState(
      {
        dialog: false,
        dialogOptions: {},
      },
      () => {
        if (callback) {
          callback();
        }
      }
    );
  };

  showNextDialog() {
    const nextDialog = this.dialogQueue.shift();
    if (nextDialog) {
      this.showDialog(nextDialog);
      return true;
    } else {
      return false;
    }
  }

  ensureDialogDisplay = () => {
    if (this.state.dialog) {
      this.onDialogInShowState();
    } else {
      this.showNextDialog();
    }
  };

  restActivity = () => {
    this.setState({
      activity: {
        disposition: 'window', // inline or window, default window
        donwloadType: 'maunal', // maunal or auto, default is maunal
      },
    });
  };

  onDialogInShowState() {
    const lastActive = document.activeElement;

    this.refs.dialog.show();
    this.refs.dialog.on('closed', () => {
      if (this.state.dialog) {
        // close by endKey
        this.hideDialog();
      }
      if (this.showNextDialog() === false) {
        lastActive.focus();
      }
    });
  }

  onDialogInHideState() {
    this.refs.dialog.hide();
  }

  onRouteChange = event => {
    if (this.entryURL === null) {
      this.entryURL = event.url;
    }
    this.currentRoute = event.url;

    this.setState({ isViewingSetting: event.url === PATH.SETTING.MATCH });
  };

  render() {
    return (
      <div
        className={
          'App ' +
          (this.state.isViewingSetting ? 'settings ' : 'bright ') +
          (navigator.largeTextEnabled ? 'large-text' : ' ')
        }
        id="app"
        tabIndex="-1"
      >
        <Router onChange={this.onRouteChange}>
          <LoadingPanel
            path={PATH.LOADING.MATCH}
            noAvailableAPP={this.state.noAvailableAPP}
          />
          {this.state.readyToRoute && (
            <AppsPanel
              path={PATH.APPS.MATCH}
              ensureDialogDisplay={this.ensureDialogDisplay}
              default
            />
          )}
          <SettingsPanel path={PATH.SETTING.MATCH} />
          <SearchPanel path={PATH.SEARCH.MATCH} locales={this.language} />
          {this.state.readyToRoute && (
            <PagePanel
              path={PATH.PAGE.MATCH}
              onAppNotFound={this.showNotFoundDialog}
            />
          )}
        </Router>
        <div
          className={'soft-key-wrapper ' + (this.state.dialog ? 'default' : '')}
        >
          <ReactSoftKey />
        </div>
        <div id="main-dialog" className={this.state.dialog ? '' : 'hidden'}>
          <ReactDialog
            ref={dialog => {
              this.refs['dialog'] = dialog;
            }}
            {...this.state.dialogOptions}
          />
        </div>
      </div>
    );
  }
}

navigator.mozL10n.once(() => {
  ReactDOM.render(<App />, document.getElementById('root'));
});
