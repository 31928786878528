// TODO: should refactor querySelectorAll related code
import React, { Component } from 'react';
import SoftKeyStore from 'soft-key-store';
import { route } from 'preact-router';
import {
  validFeature,
  validSettingData,
  validSettingKey,
} from 'kaistore-post-messenger/lib/constants';
import { MessageSender } from 'web-message-helper';
import {
  RequestHasFeatureCommand,
  RequestSettingCommand,
  UpdateSettingCommand,
} from 'kaistore-post-messenger/src/commands';
import { Setting } from 'kaistore-post-messenger/src/models';

import RichContentHelper from '@/helper/richcontent-helper';
import { PATH } from '@/constant';
import Account from '@/account';
import 'scss/SettingsPanel.scss';

const sendCommand = command => {
  return new Promise((resolve, reject) => {
    MessageSender.send(command, (success, result) => {
      if (success) {
        resolve(result.value);
      } else {
        reject(new Error(result.error));
      }
    });
  });
};

class SettingsPanel extends Component {
  constructor() {
    super();
    this.settingKeys = [
      validSettingKey.AUTO_DOWNLOAD,
      validSettingKey.RICH_CONTENT,
    ];
    this.availableSettings = this.settingKeys.reduce(
      (acc, key) => ({ ...acc, [key]: validSettingData[key].values }),
      {}
    );
    this.isWiFiCertified = false;
    this.state = {
      currentIndex: 0,
      [validSettingKey.AUTO_DOWNLOAD]: null,
      [validSettingKey.RICH_CONTENT]: null,
    };
  }

  componentDidMount() {
    this._focus();
    this.element.addEventListener('keydown', this.handleKeydown.bind(this));
    this.element.addEventListener('focus', this.handleFocus.bind(this));
    this.getAvailableSettings();
    this.getCurrentSettings();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentIndex !== this.state.currentIndex) {
      this._focus();
    }
  }

  async getAvailableSettings() {
    const featureKeys = [validFeature.HAS_WIFI, validFeature.IS_WIFI_CERTIFIED];
    const featurePromises = featureKeys.map(key => {
      const command = new RequestHasFeatureCommand({ detail: { key } });
      return sendCommand(command);
    });
    const [hasWiFi, isWiFiCertified] = await Promise.all(featurePromises).catch(
      e => console.error(e)
    );
    this.availableSettings = this.settingKeys.reduce(
      (acc, key) => ({
        ...acc,
        [key]: hasWiFi
          ? validSettingData[key].values
          : [validSettingData[key].values[0], validSettingData[key].values[1]],
      }),
      {}
    );
    // The WiFi of phones released before we have WiFi certificate feature (will get undefined) are all certified
    this.isWiFiCertified =
      isWiFiCertified === true || isWiFiCertified === undefined;
  }

  getCurrentSettings() {
    const keys = this.settingKeys;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const command = new RequestSettingCommand({ detail: { key } });
      MessageSender.send(command, (success, result) => {
        let value = validSettingData[key].default;
        if (success) {
          value = result.value;
        }
        this.updateMenuStatus(key, value);
      });
    }
  }

  updateMenuStatus(key, value, updateLayoutOnly = true) {
    const dom = document.querySelector(`select[data-setting="${key}"]`);
    dom.value = value;
    this.setState({
      [key]: value,
    });

    if (key === validSettingKey.RICH_CONTENT) {
      RichContentHelper.settingValue = value;
    }

    if (updateLayoutOnly === false) {
      const setting = new Setting({ key, value });
      MessageSender.send(new UpdateSettingCommand({ detail: setting }));
    }
  }

  _nav(move, e) {
    const items = this.element.querySelectorAll('.item');
    let nextIndex = parseInt(this.state.currentIndex, 10) + move;
    if (nextIndex < 0 || nextIndex >= items.length) {
      e.stopPropagation();
    } else {
      nextIndex %= items.length;
      this.setState({ currentIndex: nextIndex });
      e.stopPropagation();
    }
  }

  handleKeydown(e) {
    switch (e.key) {
      case 'ArrowUp':
        this._nav(-1, e);
        break;
      case 'ArrowDown':
        this._nav(1, e);
        break;
      case 'Enter':
        if (this.menu) {
          this.menu.focus();
        } else {
          // Open Account
          Account.openAccount('accounts');
        }
        break;
      case 'EndCall':
      case 'BrowserBack':
      case 'Backspace':
      case 'Escape':
        route(PATH.APPS.URL());
        e.preventDefault();
        e.stopPropagation();
        break;
      case 'ArrowRight':
      case 'ArrowLeft':
      case 'Accept':
      default:
        // do nothing, pass to parent.
        break;
    }
  }

  _focus() {
    const { currentIndex } = this.state;
    const box = this.element.querySelector('.item-list');

    const items = box.querySelectorAll('.item');
    const item = items[currentIndex];

    const border = box.offsetTop + box.offsetHeight;

    const targetBorder = box.offsetTop + item.offsetTop + item.offsetHeight;
    let offset = 0;

    if (currentIndex === 0) {
      offset = 0;
    } else if (border < targetBorder) {
      offset = border - targetBorder;
    }

    box.style.transform = `translateY(${offset}px)`;
    this.menu = null;
    if (item) {
      item.focus();
      this.menu = item.querySelector('.hidden-select');
      if (this.menu) {
        this.menu.addEventListener('blur', this.handleFocus.bind(this));
      }
    }

    const option = {
      left: '',
      center: 'select',
      right: '',
    };
    SoftKeyStore.register(option, this.element);
  }

  handleFocus() {
    this._focus();
  }

  handleSettingsChange(e) {
    const { target } = e;
    const key = target.dataset.setting;
    const { value } = target;
    this.updateMenuStatus(key, value, false);
  }

  mapValueToL10nId(value) {
    switch (value) {
      case 'wifi-only':
        return this.isWiFiCertified ? value : 'wlan-only';
      case 'data-enable':
        return 'all-connections';
      default:
        return value;
    }
  }

  render() {
    const accountVersion = Account.version;
    const settingItem = (key, titlel10n) => {
      return (
        <div className="item" tabIndex="1" data-setting={key}>
          <div className="content">
            <p data-l10n-id={titlel10n} />
            <span
              className="current-status"
              data-l10n-id={this.mapValueToL10nId(this.state[key])}
            />
          </div>
          <select
            className="hidden-select"
            onChange={this.handleSettingsChange.bind(this)}
            data-setting={key}
          >
            {this.availableSettings[key].map(value => (
              <option
                value={value}
                data-l10n-id={this.mapValueToL10nId(value)}
                key={value}
              />
            ))}
          </select>
        </div>
      );
    };
    return (
      <div
        className="SettingsPanel"
        tabIndex="1"
        ref={target => {
          this.element = target;
        }}
      >
        <h1 className="header" data-l10n-id="settings" />
        <div className="item-list">
          {accountVersion && (
            <div className="item" tabIndex="1">
              <div className="content">
                <p className="single-line-item" data-l10n-id="account" />
              </div>
            </div>
          )}
          {settingItem(validSettingKey.AUTO_DOWNLOAD, 'auto-update')}
          {settingItem(validSettingKey.RICH_CONTENT, 'show-rich-content')}
        </div>
      </div>
    );
  }
}

export default SettingsPanel;
