import React, { Component } from 'react';
import { route } from 'preact-router';
import ReactDialog from 'react-dialog/src/index';
import { MessageSender } from 'web-message-helper';
import { CheckIfStorageEnoughCommand } from 'kaistore-post-messenger/src/commands';
import { validStorageSections } from 'kaistore-post-messenger/lib/constants';

import AppStore from '@/app-store';
import { PATH } from '@/constant';
import UserInterfaceHelper from '@/helper/user-interface-helper';
import analyticsHelper from '@/helper/analytics-helper';

import Divider from '@/component/updateTab/Divider';
import UpdateBrick from '@/component/updateTab/UpdateBrick';
import UpdateButton from '@/component/updateTab/UpdateButton';

const FOCUSABLE_SELECTOR = '.focusable';

class AppUpdateListView extends Component {
  constructor(props) {
    super(props);
    this.navDirection = 1;

    const updatableApps = this.getUpdatableApps();

    this.state = {
      updatableSystemApps: updatableApps.system,
      updatableRemoteApps: updatableApps.remote,
      isUpdatingAll: AppStore.updateAllInProcess,
      dialog: false,
      dialogOptions: {},
      currentNavIndex: 0,
    };
    this.element = null;
    this.refs = {};
  }

  componentDidMount() {
    const lastCateCode = UserInterfaceHelper.lastVisitedCateCode;
    const appOrder = UserInterfaceHelper.cateHistory[lastCateCode];
    const appOrderOverflow = appOrder >= this.focuables.length;
    if (appOrder && lastCateCode === this.props.categoryCode) {
      const index = appOrderOverflow ? this.focuables.length - 1 : appOrder;
      this.setState({
        currentNavIndex: index,
      });
    }
    window.addEventListener('appstore:change', this.handleStoreChange);
    this.focus();
  }

  componentWillUnmount() {
    this.element = null;
    const { categoryCode } = this.props;
    UserInterfaceHelper.lastVisitedCateCode = categoryCode;
    UserInterfaceHelper.cateHistory[categoryCode] = this.state.currentNavIndex;
    window.removeEventListener('appstore:change', this.handleStoreChange);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.dialog !== this.state.dialog) {
      if (this.state.dialog) {
        this.onDialogInShowState();
      } else {
        this.onDialogInHideState();
      }
    }
    if (prevState.currentNavIndex !== this.state.currentNavIndex) {
      this.focus();
    }
    const updatableRemoteAppsChanged =
      prevState.updatableRemoteApps.length !==
        this.state.updatableRemoteApps.length ||
      prevState.updatableSystemApps.length !==
        this.state.updatableSystemApps.length;
    const navIndexOverflow =
      this.state.currentNavIndex >= this.focuables.length;
    if (updatableRemoteAppsChanged && navIndexOverflow) {
      this.setState({ currentNavIndex: this.focuables.length - 1 });
    }
  }

  focus() {
    if (!this.element) {
      return;
    }
    const index = this.state.currentNavIndex;
    const element =
      index >= this.focuables.length
        ? this.focuables[this.focuables.length - 1]
        : this.focuables[index];
    if (!element) {
      return;
    }
    element.focus();
    element.scrollIntoView({
      // set vertical alignment to bottom when moving upwards and vice versa
      // to ensure the component could be shown completely
      // also set 'end' when index = 0 so top divider could be shown completely
      block: this.navDirection > 0 || index === 0 ? 'end' : 'start',
    });
  }

  handleStoreChange = () => {
    const updatableApps = this.getUpdatableApps();
    this.setState({
      updatableSystemApps: updatableApps.system,
      updatableRemoteApps: updatableApps.remote,
    });
  };

  getUpdatableApps() {
    let result = {};
    this.updatableMeta.forEach(item => {
      const storeKey = item.storeKey;
      let apps = [];
      AppStore.updatableApps[storeKey].forEach((value, key) => {
        const app = AppStore.findAppByManifest(key);
        if (app) {
          apps.push(app);
        }
      });
      result[storeKey] = apps;
    });

    return result;
  }

  onUpdateBtnPressed(type, enable) {
    MessageSender.send(
      new CheckIfStorageEnoughCommand({
        detail: { section: validStorageSections.UPDATE },
      }),
      isEnough => {
        if (!isEnough) {
          return;
        }
        if (type === 'system' && enable && AppStore.updateAllRebootNeeded) {
          this.showRebootNeededDialog();
        } else {
          AppStore.updateAll(type, enable);
          this.setState({
            isUpdatingAll: {
              ...this.state.isUpdatingAll,
              [type]: enable,
            },
          });
        }
      }
    );
  }

  onBrickPress = app => {
    UserInterfaceHelper.lastAppsviewIndex.appOrder = this.state.currentNavIndex;
    route(PATH.PAGE.URL({ manifest: app.manifestURL }));
  };

  onBrickFocus = app => {
    const index = this.state.currentNavIndex;
    analyticsHelper.saveViewedApp({
      appOrder: index,
      appId: app.id,
      categoryCode: 'update',
    });
  };

  showRebootNeededDialog() {
    const dialogOptions = {
      header: 'update-all',
      type: 'confirm',
      ok: 'update',
      content: 'update-all-and-restart-dialog',
      onOk: () => {
        AppStore.updateAll('system', true);
      },
    };

    this.setState({
      dialog: true,
      dialogOptions: dialogOptions,
    });
  }

  hideDialog = () => {
    this.setState({
      dialog: false,
    });
  };

  onDialogInShowState() {
    const lastActive = document.activeElement;
    this.refs.dialog.show();

    this.refs.dialog.on('closed', () => {
      if (this.state.dialog) {
        // close by endKey
        this.hideDialog();
      }
      lastActive.focus();
    });
  }

  onDialogInHideState() {
    this.refs.dialog.hide();
    this.focus();
  }

  handleKeydown = e => {
    switch (e.key) {
      case 'ArrowUp':
        this._nav(-1, e);
        break;
      case 'ArrowDown':
        this._nav(1, e);
        break;
      case 'ArrowRight':
      case 'ArrowLeft':
      case 'BrowserBack':
      case 'Accept':
      case 'Enter':
      default:
        // do nothing, pass to parent.
        break;
    }
  };

  handleFocus = () => {
    this.focus();
  };

  _nav(move, e) {
    const navNumber = this.focuables.length;
    let nextIndex = parseInt(this.state.currentNavIndex, 10) + move;
    this.navDirection = move;

    if (nextIndex < 0) {
      this.setState({ currentNavIndex: 0 });
    } else if (nextIndex < navNumber) {
      nextIndex %= navNumber;
      this.setState({ currentNavIndex: nextIndex });
    }
    e.stopPropagation();
  }

  get updatableMeta() {
    return [
      {
        stateKey: 'updatableSystemApps',
        l10nId: 'system-software-update',
        storeKey: 'system',
      },
      {
        stateKey: 'updatableRemoteApps',
        l10nId: 'app-update',
        storeKey: 'remote',
      },
    ];
  }

  get focuables() {
    return this.element.querySelectorAll(FOCUSABLE_SELECTOR);
  }

  render() {
    const { isUpdatingAll } = this.state;

    return (
      <div
        className="AppListView"
        tabIndex="1"
        ref={element => {
          this.element = element;
        }}
        onFocus={this.handleFocus}
        onKeyDown={this.handleKeydown}
      >
        {this.updatableMeta.map(item => {
          const updatableApps = this.state[item.stateKey];
          if (updatableApps.length === 0) {
            return false;
          }
          return (
            <div className="section">
              <DividerSection
                updatableApps={updatableApps}
                l10nId={item.l10nId}
              />
              <UpdateButton
                l10nId={isUpdatingAll[item.storeKey] ? 'stop' : 'update-all'}
                onPress={() => {
                  this.onUpdateBtnPressed(
                    item.storeKey,
                    !isUpdatingAll[item.storeKey]
                  );
                }}
              />
              <AppList
                updatableApps={updatableApps}
                onPress={this.onBrickPress}
                onBrickFocus={this.onBrickFocus}
              />
            </div>
          );
        })}
        <div
          id="update-dialog-root"
          className={this.state.dialog ? '' : 'hidden'}
        >
          <ReactDialog
            ref={dialog => {
              this.refs['dialog'] = dialog;
            }}
            {...this.state.dialogOptions}
          />
        </div>
      </div>
    );
  }
}

const DividerSection = props => {
  const { updatableApps, l10nId } = props;
  return (
    updatableApps.length > 0 && (
      <Divider l10nId={l10nId} number={updatableApps.length} />
    )
  );
};

const AppList = props => {
  const { updatableApps, onPress, onBrickFocus } = props;
  return updatableApps.map((app, index) => (
    <UpdateBrick
      localized={app.info.localized}
      size={app.info.size}
      icon={app.info.icon}
      isDownloading={app.state.downloading}
      downloadProgress={app.mozAPP.progress}
      isPending={app.state.updatePending}
      key={`update-brick-${index}`}
      onPress={() => {
        onPress && onPress(app);
      }}
      onBrickFocus={() => {
        onBrickFocus && onBrickFocus(app);
      }}
    />
  ));
};

export default AppUpdateListView;
