import '../../../scss/component/UpdateBrick.scss';

import React, { Component } from 'react';

import { isOverflown } from '../../helper/user-interface-helper';

import ProgressBar from 'progress-bar';
import Marquee from '../Marquee';

class UpdateBrick extends Component {
  state = {
    isFocusing: false,
    isOverflown: false,
  };

  constructor(props) {
    super(props);
  }

  onKeyDown = evt => {
    const { onPress } = this.props;
    if (evt.key === 'Enter' && onPress) {
      onPress(evt);
    }
  };

  onFocus = () => {
    const state = {
      isFocusing: true,
    };
    if (isOverflown(this.name)) {
      state.isOverflown = true;
    }
    this.setState(state);
    this.props.onBrickFocus && this.props.onBrickFocus();
  };

  onBlur = () => {
    this.setState({ isFocusing: false });
  };

  render() {
    const {
      localized,
      size,
      icon,
      isDownloading,
      isPending,
      downloadProgress,
    } = this.props;

    const { isFocusing, isOverflown } = this.state;

    const iconWrapperStyle = icon ? { backgroundImage: `url(${icon})` } : {};
    return (
      <div
        className="update-brick focusable"
        tabIndex="1"
        onKeyDown={this.onKeyDown}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
      >
        <div className="icon-wrapper" style={iconWrapperStyle} />
        <div className="content">
          <div
            className="name"
            ref={element => {
              this.name = element;
            }}
          >
            {isFocusing && isOverflown ? (
              <Marquee>
                <div>{localized.name}</div>
              </Marquee>
            ) : (
              <div>{localized.name}</div>
            )}
          </div>
          {isDownloading ? (
            <div className="progress-bar-wrapper">
              <ProgressBar
                height={0.6}
                hasTip={true}
                infinite={downloadProgress > 0 ? false : true}
                percentage={downloadProgress}
              />
            </div>
          ) : (
            <div className="size">{size}</div>
          )}
        </div>
        <div className="indicator">
          {isPending ? (
            <div className="pending-icon" />
          ) : (
            <div data-icon="forward" className="arrow" />
          )}
        </div>
      </div>
    );
  }
}

export default UpdateBrick;
