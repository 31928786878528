import React, { Component } from 'react';
import SoftKeyStore from 'soft-key-store';
import { MessageSender } from 'web-message-helper';
import { RemoteUIReadyCommand } from 'kaistore-post-messenger/src/commands';
import { route } from 'preact-router';
import { AppsView, OptionMenu } from './component';
import { utils } from '@/utils';
import { PATH } from '@/constant';
import UserInterfaceHelper from '@/helper/user-interface-helper';
import CacheHelper from '@/helper/cache-helper';

import 'scss/AppsPanel.scss';

class AppsPanel extends Component {
  constructor(props) {
    super(props);
    this.appsPanelRef = React.createRef();
    this.appsViewRef = React.createRef();
    this.state = {
      showOptionMenu: false,
    };
  }

  componentDidMount() {
    if (UserInterfaceHelper.UIReadyCommandSent) {
      this.init();
    } else {
      const timestamp = new Date().getTime();
      performance.mark('REMOTE_PAGE_RENDERING_END');
      MessageSender.send(
        new RemoteUIReadyCommand({
          detail: {
            timestamp,
            isInlineActivity: false,
          },
        }),
        () => {
          UserInterfaceHelper.UIReadyCommandSent = true;
          this.init();
          utils.performanceLogs(timestamp);
          // only update cached item when UI is ready for the first time
          CacheHelper.updateCachedItem();
        }
      );
    }
  }

  focusOnAppsView = () => {
    this.appsViewRef.current.handleFocus();
    const option = {
      left: 'search',
      center: 'select',
      right: 'options',
    };
    SoftKeyStore.register(option, this.appsPanelRef.current);
  };

  toggleOptionMenu = () => {
    const { showOptionMenu } = this.state;
    UserInterfaceHelper.UIState.optionMenu = !showOptionMenu;
    if (showOptionMenu) {
      this.focusOnAppsView();
    }
    this.setState({ showOptionMenu: !showOptionMenu });
  };

  init() {
    this.focusOnAppsView();
    this.props.ensureDialogDisplay();
  }

  handleKeydown = e => {
    const { showOptionMenu } = this.state;
    switch (e.key) {
      case 'Enter': {
        const { target } = e;
        this.handleEnter(target);
        break;
      }
      case 'SoftRight': {
        if (!showOptionMenu) this.toggleOptionMenu();
        break;
      }
      case 'SoftLeft': {
        if (!showOptionMenu) route(PATH.SEARCH.URL());
        break;
      }
      default:
        break;
    }
  };

  handleEnter(target) {
    const { id, appOrder } = target.dataset;
    if (!id) {
      return;
    }
    route(PATH.PAGE.URL({ id, appOrder: parseInt(appOrder, 10) }));
  }

  render() {
    // TODO: flatten the component tree for easier focus management by moving child components in AppsView to here.
    // We need <CategoryTab />, <(update)AppListView />, and <OptionMenu />
    // need to review focus handling when this is done
    const { showOptionMenu } = this.state;
    return (
      <div
        className="Appspanel"
        tabIndex="1"
        ref={this.appsPanelRef}
        onKeyDown={this.handleKeydown}
      >
        <AppsView ref={this.appsViewRef} />
        <OptionMenu
          toggleOptionMenu={this.toggleOptionMenu}
          showOptionMenu={showOptionMenu}
        />
      </div>
    );
  }
}

export default AppsPanel;
