import { fetchThroughClient } from './http-request-helper';
import { deviceUtils } from '@/device-utils';

/** A class to handle search related operations.
 */
class SearchHelper {
  /**
   * Initializes variables.
   */
  constructor() {
    this._searchBaseUrl = window.deviceInfos.get('apps.searchBaseUrl');
    this._apiName = '/apps';
    this._defaultParams = this._getDefaultParams();
  }

  /**
   * Get default params for the following searches
   * @return {Object} The default param object.
   * @private
   */
  _getDefaultParams() {
    // Bug 101754#c2, set 20 as default, and no need to handle pagination.
    const PAGE_SIZE = 20;
    const { bookmarkDBSupported } = deviceUtils.featureset;
    const {
      connection = {},
      settings: deviceInfo = {},
    } = deviceUtils.information;
    const {
      defaultServiceId,
      imei,
      currentMCC,
      currentMNC,
      currentMCC2,
      currentMNC2,
    } = connection;
    const communicationParams = {
      imei: imei || '',
      mcc: defaultServiceId === 0 ? currentMCC : currentMCC2,
      mnc: defaultServiceId === 0 ? currentMNC : currentMNC2,
    };
    const deviceParams = {
      curef: deviceInfo.get('deviceinfo.cu'),
      platform: deviceInfo.get('deviceinfo.os').split(' ')[0],
      bookmark: bookmarkDBSupported,
    };
    const pageParams = {
      // use 0 for the first page
      page: 0,
      size: PAGE_SIZE,
    };
    return { ...communicationParams, ...deviceParams, ...pageParams };
  }

  /**
   * Get the search parameter with device infos.
   * @param {String} query - The keyword to search.
   * @param {String} locale - The intended locale of search result.
   * @return {Object} The request parameters for searching apps.
   * @private
   */
  _getQuerystringObject(query, locale) {
    const queryParams = { query, locale };
    return { ...this._defaultParams, ...queryParams };
  }

  /**
   * Combine the device and query params with baseURL and apiName to the complete endpoint.
   * @param {String} params - The prefix of the endpoint you are using.
   * @return {String} - The endpoint URL
   * @private
   */
  _compositeSearchURL(params) {
    const queries = Object.entries(params).reduce(
      (acc, [key, value]) => acc.concat(`&${key}=${value}`),
      ''
    );
    const queryString = queries.replace('&', '?');
    return `${this._searchBaseUrl}${this._apiName}${queryString}`;
  }

  /**
   * Search apps by keywords
   * @param {String} keyword - The keyword user want to search, default to
   * empty string.
   * @param {String} locale - The intended locale for search result
   * @return {Promise} Apps in the "data" field.
   */
  searchApps(keyword = '', locale = 'en-US') {
    const queryObj = this._getQuerystringObject(keyword, locale);
    const url = this._compositeSearchURL(queryObj);
    return fetchThroughClient(url, { useDeviceInfo: true });
  }
}

export default SearchHelper;
