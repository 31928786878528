'use strict';

import downloadIcon from '../scss/ic_download.png';

class IconConverter {
  constructor() {
    // Add icon_file_path in this.iconList for setting base64 images in the Map.
    this.iconList = {
      download: downloadIcon,
    };
    this.base64Icons = new Map();
    this._init();
  }

  getBase64(key) {
    return this.base64Icons.get(key);
  }

  _getBase64Icon(filePath) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      let _getBase64Image = image => {
        // Create an empty canvas element
        let canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;

        // Copy the image contents to the canvas
        var ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0);

        // Get the data-URL formatted image
        return canvas.toDataURL('image/png');
      };

      img.onload = () => {
        resolve(_getBase64Image(img));
      };
      img.onerror = evt => {
        reject(evt);
      };
      img.src = filePath;
    });
  }

  _init() {
    let list = this.iconList;
    let keys = Object.keys(list);

    for (let i = 0; i < keys.length; i++) {
      if (list.hasOwnProperty(keys[i])) {
        this._getBase64Icon(
          '../' + __VERSION__ + '/dist/' + list[keys[i]]
        ).then(
          result => {
            this.base64Icons.set(keys[i], result);
          },
          error => {
            console.warn(
              'Failed to get base64 image "' + icon + '"',
              JSON.stringify(error)
            );
          }
        );
      }
    }
  }
}

var iconConverterInstance = new IconConverter();
export default iconConverterInstance;
