import 'scss/AppsView.scss';

import React, { Component } from 'react';
import AppListView from './AppListView';
import AppUpdateListView from './AppUpdateListView';
import CategoryTabs from './CategoryTabs';

import AppStore from '@/app-store';
import UserInterfaceHelper from '@/helper/user-interface-helper';
import analyticsHelper from '@/helper/analytics-helper';

const _ = navigator.mozL10n.get;

class AppsView extends Component {
  constructor(props) {
    super(props);
    this.carrierCategory = AppStore.categories.carrier;
    this.otherCategories = [
      ...AppStore.categories.recommended,
      ...AppStore.categories.static,
    ];
    this.appsViewRef = React.createRef();
    this.appListViewRef = React.createRef();
    this.tabRefs = {};
    const categories = this.getCategories();
    this.state = {
      // categories will change if there is new updates or updates are done
      categories,
      currentCateCode: categories[0].code,
    };
  }

  componentDidMount() {
    window.addEventListener('appstore:change', this.handleStoreChange);
    const lastCateCode = UserInterfaceHelper.lastVisitedCateCode;
    const { categories } = this.state;
    const cateIndex = categories.findIndex(cate => cate.code === lastCateCode);
    if (cateIndex !== -1) {
      this.setState({
        currentCateCode: lastCateCode,
      });
    } else {
      this._activeTab();
    }
  }

  // FIXME: could move this to the callback of each setState call
  componentDidUpdate(prevProps, prevState) {
    const { categories: currentCategories } = this.state;
    const { categories: prevCategories } = prevState;
    if (prevState.currentCateCode !== this.state.currentCateCode) {
      this._activeTab();
    }
    if (prevCategories.length !== currentCategories.length) {
      const isCurrentCateExist = currentCategories.find(category => {
        return category.code === this.state.currentCateCode;
      });
      if (!isCurrentCateExist) {
        /**
         * Fallback to first cate if current cate disappeared, it could only be
         * update tab at this stage
         */
        this.setState({
          currentCateCode: currentCategories[0].code,
        });
      } else {
        this._activeTab();
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('appstore:change', this.handleStoreChange);
    // the whole component will be unmounted, so save the last visited Tab's category code for returning visit
    const { currentCateCode, categories } = this.state;
    UserInterfaceHelper.lastVisitedCateCode = currentCateCode;
  }

  get currentCateIndex() {
    const { currentCateCode, categories } = this.state;
    // return 0 if category code is not found
    return Math.max(
      categories.findIndex(category => {
        return category.code === currentCateCode;
      }),
      0
    );
  }

  getCategories() {
    // Order of categories: carrier > update > recommended > static
    const updateCate = {
      displayName: _('update'),
      code: 'update',
    };
    const categories = AppStore.hasUpdatableApps
      ? [...this.carrierCategory, updateCate, ...this.otherCategories]
      : [...this.carrierCategory, ...this.otherCategories];
    this.tabRefs = categories.reduce(
      (acc, cate) => ({
        ...acc,
        [cate.code]: React.createRef(),
      }),
      // This initial object is for the parentElement of the tabs
      { box: React.createRef() }
    );
    return categories;
  }

  handleStoreChange = () => {
    this.setState({
      categories: this.getCategories(),
    });
  };

  handleFocus = () => {
    this._focus();
  };

  handleKeydown = e => {
    switch (e.key) {
      case 'ArrowRight':
        e.preventDefault();
        this._nav(1);
        break;
      case 'ArrowLeft':
        e.preventDefault();
        this._nav(-1);
        break;
      case 'ArrowDown':
      case 'ArrowUp':
        this._blur();
        break;
      case 'BrowserBack':
      case 'Backspace':
      case 'Escape':
      case 'Enter':
      case 'SoftRight':
      case 'SoftLeft':
        // pass to parent.
        break;
      case 'Accept':
      default:
        // do nothing.
        e.stopPropagation();
        break;
    }
  };

  _nav(move) {
    const { categories } = this.state;
    const reverse = document.dir === 'rtl' ? -1 : 1;
    const newCateIndex =
      (this.currentCateIndex + move * reverse + categories.length) %
      categories.length;
    if (this.currentCateIndex !== newCateIndex) {
      this.setState({ currentCateCode: categories[newCateIndex].code });
    }
  }

  _focus() {
    // FIXME: flatten the component trees for this hack.
    this.appListViewRef.current.querySelector('.AppListView').focus();
  }

  _blur() {
    this.appsViewRef.current.blur();
  }

  // TODO:: See if we could have an easier solution for this
  _activeTab() {
    let offset;
    const { categories, currentCateCode } = this.state;
    const currentTab = this.tabRefs[currentCateCode].current;

    const lastIndex = categories.length - 1;
    const lastCate = categories[lastIndex];
    const { code: lastCateCode } = lastCate;
    const lastTab = this.tabRefs[lastCateCode].current;

    // set tabName for analytical stream after getting currentTab
    this.saveViewedTab();

    // fetch UL
    const box = this.tabRefs.box.current;
    const tabsWrapperWidth = box.offsetWidth;

    const currTabLeft = currentTab.offsetLeft;
    const currTabWidth = currentTab.offsetWidth;
    let currTabRight = 0;

    // last tabs
    const lastTabLeft = lastTab.offsetLeft;
    const lastTabWidth = lastTab.offsetWidth;
    let lastTabRight = 0;
    if (document.dir === 'rtl') {
      // RTL (Right to Left)
      // In RTL, offsetLeft will not be reverted to offsetRight.
      // So first offsetLeft of tab will starts from
      // (WrapperWidth -currTabWidth), eg. Each tab's width is 40px.
      // its offsetLeft will be 160px, and 2nd tab will be 120, so on..
      // But in LTR (Left to right), first offsetLeft will start from 0px.

      if (this.currentCateIndex !== 0 && currTabLeft < 0) {
        offset = tabsWrapperWidth / 2 - (currTabLeft + currTabWidth / 2);
      } else {
        // First tab, translateX offset is 0.
        offset = 0;
      }
    } else {
      // LTR (Left to Right)
      currTabRight = tabsWrapperWidth - (currTabLeft + currTabWidth);
      lastTabRight = tabsWrapperWidth - (lastTabLeft + lastTabWidth);
      if (this.currentCateIndex === 0) {
        // First tab, translateX offset is 0.
        offset = 0;
      } else if (this.currentCateIndex === lastIndex) {
        // Last tab, translateX offset is lastTabRight or 0.
        if (lastTabRight > 0) {
          offset = 0;
        } else {
          offset = (currTabLeft + currTabRight) / 2 - currTabLeft;
        }
      } else {
        // Tab in the middle of somewhere

        // Calculate the average of current tab's offsetLeft and offsetRight
        // as targetOffset and set translateX to put tab at the center.
        const targetTabLeft = (currTabLeft + currTabRight) / 2;
        const targetOffset = targetTabLeft - currTabLeft;
        if (targetOffset > 0 || lastTabRight > 0) {
          // Offset greater than 0, possibly at the first one or two tabs,
          // or last tab is fully visible, so there's no need move.
          offset = 0;
        } else if (targetOffset < 0 && targetOffset > lastTabRight) {
          // Normal case, simply set offset to targetOffset.
          offset = targetOffset;
        } else if (targetOffset < 0 && targetOffset < lastTabRight) {
          // Offset less than lastTabRight,
          // possibly at the last one or two tabs
          // and can't move more than lastTabRight.
          offset = lastTabRight;
        }
      }
    }
    // apply css.
    box.style.transform = `translateX(${offset}px)`;
  }

  saveViewedTab() {
    const { currentCateCode, categories } = this.state;
    analyticsHelper.saveViewedTab({
      cateCode: currentCateCode,
      firstTabCode: categories[0].code,
    });
  }

  render() {
    const { categories, currentCateCode } = this.state;
    return (
      <div
        className="AppsView"
        tabIndex="1"
        ref={this.appsViewRef}
        onKeyDown={this.handleKeydown}
        onFocus={this.handleFocus}
      >
        <CategoryTabs
          categories={categories}
          currentCateIndex={this.currentCateIndex}
          ref={this.tabRefs}
        />
        <div className="ListviewBox" ref={this.appListViewRef}>
          {currentCateCode === 'update' ? (
            <AppUpdateListView
              order={this.currentCateIndex}
              categoryCode={currentCateCode}
            />
          ) : (
            <AppListView categoryCode={currentCateCode} />
          )}
        </div>
      </div>
    );
  }
}

export default AppsView;
