// TODO: rename it from UserInterfaceHelper to ViewsHelper for brevity
import { MessageSender } from 'web-message-helper';
import { ChangeThemeColorCommand } from 'kaistore-post-messenger/src/commands';

/**
 * Saves last navigated index before user enter app detail page.
 * @typedef {lastAppsviewIndex} lastAppsviewIndex
 * @property {integer} tabOrder - x index
 * @property {integer} appOrder - y index
 */

/**
 * HTML DOM
 * @typedef {DOM} DOM
 */

/** A class to handle user inteface related operations. */
class UserInterfaceHelper {
  /**
   * Initializes variables.
   */
  constructor() {
    /**
     * @type {lastAppsviewIndex}
     * @public
     */
    // TODO: clean this after shared AppListView (only used by SearchPanel now) is refactored
    this.lastAppsviewIndex = {
      tabOrder: null,
      appOrder: null,
    };

    // The last visited category code for AppsPanel
    this.lastVisitedCateCode = null;
    // The viewing history of every category.
    // Used for getting back to the last viewed app when returning to the category
    // key: cateCode, val: appOrder
    this.cateHistory = {};

    /**
     * Defines if tiny store has notified client about UI is ready.
     * @type Boolean
     * @public
     */
    this.UIReadyCommandSent = false;
    this.UIState = {
      dialog: false,
      optionMenu: false,
    };
  }

  get popupVisible() {
    return Object.values(this.UIState).some(item => item);
  }

  /**
   * Starts UI related listeners.
   * @type {Function}
   * @public
   */
  startListeners() {
    window.addEventListener('visibilitychange', this.handleVisibilityChange);
  }

  /**
   * Closes window when document is invisible. (e.g screen dim)
   * @type {Function}
   * @private
   */
  handleVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      window.close();
      return;
    }
  };
}

export default (UserInterfaceHelper = new UserInterfaceHelper());

/**
 * Checks if the element is overflown.
 * @method isOverflown
 * @param {DOM} element - HTML DOM element
 * @return {Boolean}
 */
export const isOverflown = element => {
  if (!element) {
    return false;
  }
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
};

/**
 * Request Store client to change theme color.
 * @method setThemeColor
 * @param {Boolean} isViewingSetting - Is on the setting panel or not
 */
export const setThemeColor = isViewingSetting => {
  const detail = {
    content: isViewingSetting ? 'rgb(50, 3, 116)' : 'rgb(255, 255, 255)',
  };
  MessageSender.send(new ChangeThemeColorCommand({ detail: detail }));
};
