import '../../../scss/component/UpdateButton.scss';

import React from 'react';

const UpdateButton = React.memo(function UpdateButton(props) {
  const { l10nId, onPress } = props;
  const onKeyDown = evt => {
    if (evt.key === 'Enter' && onPress) {
      onPress(evt);
    }
  };
  return (
    <div className="update-btn focusable" tabIndex="1" onKeyDown={onKeyDown}>
      <div data-l10n-id={l10nId} />
    </div>
  );
});

export default UpdateButton;
