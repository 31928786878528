import { route } from 'preact-router';

import { PATH } from '@/constant';
import AppStore from '@/app-store';
import ActivityLauncher from '@/helper/activity-launcher';

const INLINE_ACTIVITY_NAME = ['inline-open-page', 'inline-open-by-name'];

class MozActivityManager {
  constructor() {
    this.iac_kaipay = 'kaipay-to-kaistore';
    this.taskQueue = [];
    /*
     * While using postResult to exit, we should preventDefault the back key
     * event. Otherwise, it will close the caller app as well.
     */
    this.shouldPostResultToCaller = false;
  }

  handleActivity(activityRequest) {
    var option = activityRequest.source;
    switch (option.name) {
      case 'open-page':
      case 'open-by-name':
      case 'inline-open-page':
      case 'inline-open-by-name':
        this.saveToQueue(option);
        break;
      default:
        break;
    }
  }

  // XXX: In the future, we will use these order info to recover the intermittent orders.
  // If the order has been completed, we will remove it after download success.
  handleIAC(iacRequest) {
    // Inter APP Communication with KaiPay
    if (iacRequest.keyword === this.iac_kaipay) {
      let port = iacRequest.port;
      port.onmessage = function onReceivedMessage(evt) {
        let eventData = evt.data;
        let productId = eventData.productID;
        let orderData = eventData.data;
        let orderId = orderData.orderId;

        if (productId && orderId) {
          // add update time to data
          eventData.updateTime = evt.timeStamp;
          // insert or update the order info into indexDB (use "kaipay-{productId}" to be the key)
          asyncStorage.setItem(`${this.iac_kaipay}-${productId}`, eventData);
        }
      }.bind(this);
    }
  }

  saveToQueue(option) {
    if (!option) {
      return;
    }
    this.taskQueue.push(option);
    window.dispatchEvent(new CustomEvent('task-queue-updated'));
  }

  get task() {
    if (this.taskQueue.length === 0) {
      return false;
    } else {
      return this.taskQueue.pop();
    }
  }

  async findActiveAppByTask(task) {
    const { name: taskName } = task;
    const { type: taskType } = task.data;
    let graphQLQuery = null;
    let application = null;

    switch (taskType) {
      case 'name':
        const { name } = task.data;
        application = AppStore.findAppByName(name);
        graphQLQuery = { name };
        break;
      case 'url':
      case 'push-app':
        const { url } = task.data;
        const { searchParams } = new URL(url);
        const activeManifestURL =
          taskName === 'open-deeplink' ? searchParams.get('apps') : url;
        application = AppStore.findAppByManifest(activeManifestURL);
        graphQLQuery = { manifestURL: activeManifestURL };
        break;
      default:
        console.warn(`invalid activity task`, task);
        return null;
    }

    if (application && application.isInRemoteList) {
      return application;
    }

    const activityLauncher = new ActivityLauncher();

    try {
      const queryApp = await activityLauncher.getAppFromGraphQL(graphQLQuery);
      AppStore.initRemoteApps([queryApp]);
      return queryApp;
    } catch (err) {
      console.warn('failed to getAppFromGraphQL ', err);
      return null;
    }
  }

  async handleTask(task, handleAppNotFound) {
    const { name: taskName } = task;
    const { type: taskType, adId, autoDownload, pixel } = task.data;
    const disposition = INLINE_ACTIVITY_NAME.includes(taskName)
      ? 'inline'
      : 'window';
    let shouldSearchForApp = true;
    let activeApp = null;

    if (taskName === 'open-deeplink') {
      const { url } = task.data;
      const { searchParams } = new URL(url);
      const activeManifestURL = searchParams.get('apps');
      const needPostResult = searchParams.get('postResult') === 'true';
      /*
       * If store is launched via deeplink (app://kaios-store.kaiostech.com)
       * and without having 'apps' param, we should not search for any app
       */
      shouldSearchForApp = !!activeManifestURL;
      this.shouldPostResultToCaller = needPostResult;
    }

    if (shouldSearchForApp) {
      activeApp = await this.findActiveAppByTask(task);
    }

    if (activeApp && activeApp.id) {
      route(
        PATH.PAGE.URL({
          id: activeApp.id,
          autoDownload: taskType === 'push-app' || autoDownload ? true : false,
          disposition: disposition,
          ...(adId && { adId }),
          ...(pixel && { pixel }),
        })
      );
    } else if (shouldSearchForApp) {
      handleAppNotFound();
    }
  }
}

export let mozActivityManager = new MozActivityManager();
