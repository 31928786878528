import {
  CheckAppUpdateCommand,
  InstallCommand,
  UninstallCommand,
  UpdateAppCommand,
  LaunchAppCommand,
  CancelDownloadCommand,
  ShowToastCommand,
  CheckIfStorageEnoughCommand,
} from 'kaistore-post-messenger/src/commands';
import {
  mozAppEvent as constMozAppEvent,
  validStorageSections,
  internalApps,
} from 'kaistore-post-messenger/lib/constants';
import { Toast } from 'kaistore-post-messenger/src/models';
import { MessageSender } from 'web-message-helper';

import { mozAppHelper } from '@/helper/mozapp-helper';
import analyticsHelper from '@/helper/analytics-helper';
import {
  isValidURL,
  normalizedURL,
  localeCode,
  trimBgs,
  trimIcons,
  trimLocales,
  getSize,
  getLocales,
} from '@/helper/application-helper';
import { getHigherVersion } from '@/utils';
import { deviceUtils } from '@/device-utils';

import { SUPPORTED_APP_TYPE, PRELOADED_APP } from '@/constant';
import AppStore from '@/app-store';

class Application {
  constructor(manifestURL) {
    // trim manifest url to make sure there is no space in it.
    this.manifestURL = encodeURI(manifestURL.trim());
    this._remoteInfo = null;
    this._mozAPP = null;
    this.state = {
      downloading: false,
      updatable: false,
      liked: false,
      pinned: false,
      updatePending: false,
      coreUpdated: false,
    };
    this._trimmedRemoteInfo = {};
    this._trimmedMozAPPInfo = {};
    this.purchased = false;
    this.updateChecked = false;
    this.name = null;
  }

  get isBookmark() {
    return this.info.type === 'bookmark';
  }

  get isHosted() {
    return this.info.type === 'hosted';
  }

  get isInternal() {
    return Object.values(internalApps).includes(this.id);
  }

  get isPreloaded() {
    return PRELOADED_APP.includes(this.id);
  }

  get core() {
    return Boolean(
      this.mozAPP && this.mozAPP.manifest && this.mozAPP.manifest.core
    );
  }

  get isCoreUpdating() {
    return this.core && this.state.updatable && this.state.downloading;
  }

  get isRebootNeeded() {
    return Boolean(
      this.mozAPP && this.mozAPP.manifest && this.mozAPP.manifest.reboot
    );
  }

  get id() {
    if (this.remoteInfo && this.remoteInfo.id) {
      return this.remoteInfo.id;
    }

    const manifestInfo = this.manifestURL.split('/');
    const lastPart = manifestInfo[manifestInfo.length - 1];
    if (lastPart === 'manifest.webapp') {
      return null;
    }
    return manifestInfo[manifestInfo.length - 1];
  }

  get remoteInfo() {
    return this._remoteInfo;
  }

  get mozAPP() {
    return this._mozAPP;
  }

  get isInRemoteList() {
    return this._remoteInfo !== null;
  }

  get isInstalled() {
    return Boolean(this._mozAPP && this._mozAPP.installState === 'installed');
  }

  get info() {
    const remoteInfo = this._trimmedRemoteInfo;
    const mozAPPInfo = this._trimmedMozAPPInfo;
    let version = null;
    /**
     * In order to show the newest version available for the app even when
     * remote info is fetched from cache, we show the newest version between
     * remote info and updateManifest
     */
    if (remoteInfo.version && mozAPPInfo.version) {
      version = getHigherVersion(remoteInfo.version, mozAPPInfo.version);
    } else {
      version = remoteInfo.version || mozAPPInfo.version;
    }
    return {
      bg: remoteInfo.bg || mozAPPInfo.bg || null,
      icon: remoteInfo.icon || mozAPPInfo.icon || null,
      localized: remoteInfo.localized || mozAPPInfo.localized || {},
      url: remoteInfo.url || null,
      type: remoteInfo.type || mozAPPInfo.type,
      paid: remoteInfo.paid || false,
      productId: remoteInfo.productId || null,
      version,
      size: mozAPPInfo.size ? getSize(mozAPPInfo.size) : null,
      ...(remoteInfo.paid && { unitPrice: remoteInfo.unitPrice }),
    };
  }

  get brickInfo() {
    return {
      id: this.id,
      isBookmark: this.isBookmark,
      state: this.state,
      isInstalled: this.isInstalled,
      purchased: this.purchased,
      info: this.info,
    };
  }

  get softKeyOption() {
    if (this.isBookmark) {
      const isBookmarkSupported = deviceUtils.featureset.bookmarkDBSupported;
      const { pinned } = this.state;
      return {
        left: isBookmarkSupported && !pinned ? 'pin' : '',
        center: 'open',
        right: pinned ? 'unpin' : '',
      };
    } else {
      const { isInstalled, info, state, purchased, mozAPP, core } = this;
      const { paid } = info;
      const {
        updatable,
        downloading,
        // liked,
        coreUpdated,
      } = state;

      const option = {
        left: '',
        center: '',
        right: '',
      };

      if (coreUpdated) {
        option.center = 'done';
      } else if (this.isCoreUpdating) {
        option.center = '';
      } else if (downloading) {
        option.center = 'stop';
      } else if (paid && !isInstalled && !purchased) {
        option.center = 'buy';
      } else if (isInstalled && !updatable) {
        option.center = 'go';
      } else if (isInstalled && updatable) {
        option.center = core ? 'select' : 'update';
      } else {
        option.center = 'get';
      }

      if (isInstalled && !core) {
        // option['left'] = liked ? 'unlike' : 'like'; // enable it when the backend is ready.
      }

      if (
        isInstalled &&
        mozAPP &&
        mozAPP.removable === true &&
        !this.isCoreUpdating &&
        !this.state.coreUpdated
      ) {
        option.right = 'uninstall'; // should be options.
      }

      return option;
    }
  }

  set remoteInfo(info) {
    this._remoteInfo = info;
    this._trimmedRemoteInfo = this.trimRemoteInfo(info);
    this.name = info.name;
  }

  set mozAPP(mozAPP) {
    this._mozAPP = mozAPP;
    if (mozAPP && mozAPP.updateManifest) {
      this._trimmedMozAPPInfo = this.trimMozAPPInfo(mozAPP.updateManifest);
    } else {
      this._trimmedMozAPPInfo = {};
    }
  }

  setRemoteInfo(info) {
    if (!SUPPORTED_APP_TYPE.includes(info.type)) {
      return false;
    }

    // use manifest as fallback if url is missing for bookmark
    if (info.type === 'bookmark' && !info.url) {
      info.url = info.manifest_url;
    }

    if (this.isRemoteInfoValid(info)) {
      this.remoteInfo = info;
      return true;
    }

    return false;
  }

  isRemoteInfoValid(remoteInfo) {
    if (remoteInfo.type === 'bookmark') {
      const URL = remoteInfo.url;
      return URL && isValidURL(URL);
    }
    return remoteInfo.id && remoteInfo.manifest_url;
  }

  trimRemoteInfo(remoteInfo) {
    const formatUnitPrice = (currency, price) => {
      if (currency && price) {
        return `${currency} $${price}`;
      }
      if (price) {
        return `$${price}`;
      }
      return null;
    };
    return {
      bg: trimBgs(remoteInfo.bgs),
      icon: trimIcons(remoteInfo.icons),
      localized: getLocales(remoteInfo),
      url: remoteInfo.url ? normalizedURL(remoteInfo.url) : null,
      type: remoteInfo.type,
      paid: remoteInfo.paid || false,
      productId: remoteInfo.product_id || null,
      version: remoteInfo.version || null,
      ...(remoteInfo.paid && {
        unitPrice: formatUnitPrice(remoteInfo.currency, remoteInfo.price),
      }),
    };
  }

  trimMozAPPInfo(updateManifest) {
    return {
      bg: trimBgs(updateManifest.bgs),
      icon: trimIcons(updateManifest.icons),
      localized: trimLocales(
        updateManifest.locales,
        localeCode(updateManifest)
      ),
      type: updateManifest.type,
      version: updateManifest.version || null,
      size: updateManifest.size,
    };
  }

  checkUpdate() {
    if (!this.mozAPP) {
      return;
    }
    const command = new CheckAppUpdateCommand({
      detail: {
        manifestURL: this.manifestURL,
        autoDownload: false,
      },
    });

    MessageSender.send(command);
  }

  updateState(detail) {
    this.state = { ...this.state, ...detail };
    // auto flag updatePending to false when app starts downloading
    if (detail.downloading === true) {
      this.state = { ...this.state, updatePending: false };
    }
    const evt = new CustomEvent('appstore:change', {
      detail: { manifestURL: this.manifestURL },
    });
    window.dispatchEvent(evt);
  }

  install(jwt = null) {
    this.updateState({ downloading: true });

    const command = new InstallCommand({
      detail: {
        isHosted: this.isHosted,
        manifestURL: this.manifestURL,
        jwt,
      },
    });

    return new Promise((resolve, reject) => {
      MessageSender.send(command, (success, detail) => {
        if (success === true) {
          resolve();
        } else {
          this.sendInstallData('store_app_install_failed');
          reject(detail.error);
        }
      });
    });
  }

  uninstall() {
    const command = new UninstallCommand({
      detail: {
        manifestURL: this.manifestURL,
      },
    });
    this.sendInstallData('store_app_uninstall_init');
    MessageSender.send(command);
  }

  update(skipChecking = false) {
    return new Promise((resolve, reject) => {
      const requestUpdate = () => {
        this.sendInstallData('store_app_update_init');
        this.updateState({ downloading: true });

        const command = new UpdateAppCommand({
          detail: {
            manifestURL: this.manifestURL,
          },
        });
        MessageSender.send(command, success => {
          if (success) {
            resolve();
          } else {
            reject();
          }
        });
      };

      if (skipChecking) {
        requestUpdate();
      } else {
        MessageSender.send(
          new CheckIfStorageEnoughCommand({
            detail: { section: validStorageSections.UPDATE },
          }),
          isEnough => {
            if (!isEnough) {
              return;
            }
            requestUpdate();
          }
        );
      }
    });
  }

  launch() {
    const command = new LaunchAppCommand({
      detail: {
        manifestURL: this.manifestURL,
      },
    });
    MessageSender.send(command);
  }

  cancelDownload() {
    const command = new CancelDownloadCommand({
      detail: {
        manifestURL: this.manifestURL,
      },
    });
    if (this.state.updatable) {
      this.sendInstallData('store_app_update_cancel_init');
    } else {
      this.sendInstallData('store_app_install_cancel_init');
    }
    MessageSender.send(command);
  }

  handleDownload() {
    this.updateState({
      downloading: true,
    });
  }

  handleDownloadError() {
    this.updateState({
      downloading: false,
    });
  }

  handleDownloadSuccess() {
    // Remove unused info: description & locales.description
    if (this.mozAPP) {
      mozAppHelper.releaseUnusedInfo(this.mozAPP);
    }
    this.updateState({
      downloading: false,
      updatable: false,
    });
  }

  handleUpdatedSuccess() {
    this.updateState({
      downloading: false,
      updatable: false,
      coreUpdated: this.core,
    });
    AppStore.updatableApps.system.delete(this.manifestURL);
    AppStore.updatableApps.remote.delete(this.manifestURL);
    if (this.core) {
      AppStore.shouldReboot = true;
    }
  }

  handleInstall(mozAppEvent) {
    if (this.isInstalled) {
      this.handleDownloadSuccess();
      this.sendInstallData('store_app_install_done');
      return;
    }

    if (mozAppEvent === constMozAppEvent.ON_DOWNLOAD_APPLIED) {
      this.handleDownloadSuccess();
      this.sendInstallData('store_app_install_done');
    } else if (mozAppEvent === constMozAppEvent.ON_DOWNLOAD_ERROR) {
      this.handleDownloadError();
      this.sendInstallData('store_app_install_failed');
    } else {
      this.handleDownload();
    }
  }

  handleUninstall() {
    // clear up app props.
    const appName = this.info.localized.name;
    this.mozAPP = null;

    const isEmptyApplication = Object.keys(this.info.localized).length === 0;
    if (isEmptyApplication) {
      AppStore.clearEmptyApplication(this.manifestURL);
    }

    this.updateState({
      downloading: false,
      updatable: false,
    });

    const messageOption = {
      messageL10nId: 'uninstalled-msg-with-name',
      messageL10nArgs: {
        appName,
      },
    };

    MessageSender.send(
      new ShowToastCommand({
        detail: new Toast(messageOption),
      })
    );
  }

  handleUpdate(mozAppEvent) {
    switch (mozAppEvent) {
      case constMozAppEvent.ON_DOWNLOAD_AVAILABLE:
        AppStore.syncUpdatableApp(this);
        break;
      case constMozAppEvent.ON_DOWNLOAD_APPLIED:
        this.handleUpdatedSuccess();
        this.sendInstallData('store_app_update_done');
        break;
      case constMozAppEvent.ON_DOWNLOAD_SUCCESS:
        this.handleDownloadSuccess();
        break;
      case constMozAppEvent.ON_DOWNLOAD_ERROR:
        this.handleDownloadError();
        break;
    }
  }

  handleBookmarkUpdate(pin) {
    this.updateState({ pinned: pin });
    const messageL10nId = pin
      ? 'pin-bookmark-completely'
      : 'unpin-bookmark-completely';
    MessageSender.send(
      new ShowToastCommand({
        detail: new Toast({ messageL10nId }),
      })
    );
  }

  sendInstallData(event_name = '') {
    const logInfo = {
      event_name,
      app_id: this.id,
      app_version: this.info.version,
    };
    analyticsHelper.postAppActionEvent(logInfo);
  }
}

export default Application;
