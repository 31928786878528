import { postThroughClient } from '@/helper/http-request-helper';
import { deviceUtils } from '../device-utils';
import UserInterfaceHelper from '@/helper/user-interface-helper';

const EVENT_TYPE_INITIALS = {
  STORE_SEARCH: 'store_search',
  STORE_APP_VIEW: 'store_app_view',
  STORE_APP: 'store_app',
};
class AnalyticsHelper {
  constructor() {
    this._apiUri = null;
    this._apiName = '/store';
    this._url = null;
    this.storeVersion = null;
    this.browsedHistory = [];
    this.tabInfo = {
      firstTabCode: '',
      currentTab: '',
    };
    /*
     * the queueEvents would be an object contains logs. for example:
     * {
     *   store_app_view_lj6skNdORZPHqWfrqSwY: [
     *     { event_name: 'store_app_view_init, ... },
     *     { event_name: 'store_app_view_done, ... },
     *   ],
     *   store_app_install_hXMhsZFS0I5HdxAfbQlG: [
     *     { event_name: 'store_app_view_init, ... },
     *   ],
     *   store_app_install_ahjIoexxxxxLwzAfBieK: [
     *     { event_name: 'store_app_view_init, ... },
     *   ],
     * }
     */
    this.queuedEvents = {};
    this.searchRequestId = '';
    this.lastAppAction = {
      action: '',
      appId: '',
    };
  }

  init(entryURL) {
    const searchParams = new URLSearchParams(entryURL);
    this.storeVersion = searchParams.get('version');
    this._apiUri = window.deviceInfos.get('apps.analyticsEventBaseUrl');
    // example url: 'https://analytics.test.kaiostech.com/v1/store'
    this._url = `${this._apiUri}${this._apiName}`;
  }

  saveViewedTab(tabInfo) {
    // TODO: may be able to utilize UserInterfaceHelper.lastAppsviewIndex
    const { cateCode, firstTabCode } = tabInfo;
    this.tabInfo.currentTab = cateCode;
    this.tabInfo.firstTabCode = firstTabCode;
  }

  saveViewedApp(appInfo = {}) {
    const numOfViewedCategories = Object.keys(UserInterfaceHelper.cateHistory)
      .length;
    const { appOrder, appId, categoryCode } = appInfo;
    const matchPreviousCate = categoryCode === this.tabInfo.currentTab;
    const previousRecord =
      this.browsedHistory.length > 0 &&
      this.browsedHistory[this.browsedHistory.length - 1];
    const newRecord = {
      tab: categoryCode,
      app_id: appId,
      app_order: appOrder,
      device_utc: Date.now(),
    };

    const isFirstFocusedAppInHistory =
      numOfViewedCategories === 0 &&
      categoryCode === this.tabInfo.firstTabCode &&
      !previousRecord;
    const isFirstFocusedAppInSession = !previousRecord && matchPreviousCate;
    const isDifferentApp = previousRecord && appId !== previousRecord.app_id;

    if (
      isFirstFocusedAppInHistory ||
      isFirstFocusedAppInSession ||
      isDifferentApp
    ) {
      this.browsedHistory.push(newRecord);
    }
  }

  postData(params, { appendAccountInfo = false }) {
    return postThroughClient(this._url, {
      useDeviceInfo: true,
      params,
      appendAccountInfo,
    }).catch(error => {
      console.error(`analytics: ${error.statusText}`);
    });
  }

  postAppActionEvent(data) {
    const { app_id: appId } = data;
    const logInfo = this._appendSharedFields(data);
    if (logInfo.event_name === 'store_app_uninstall_init') {
      this.postData(logInfo, { appendAccountInfo: true });
    } else {
      this._logTracker(logInfo, appId);
    }
  }

  postSearchEvent(data) {
    const { keyword, ...eventData } = data;
    const logInfo = this._appendSharedFields(eventData);
    this._logTracker(logInfo, keyword);
  }

  postAppViewEvent(data) {
    const {
      app_id: appId,
      referral_type: referralType,
      event_name: eventName,
    } = data;
    const logInfo = this._appendSharedFields(data);

    if (
      referralType === EVENT_TYPE_INITIALS.STORE_SEARCH &&
      eventName.endsWith('init')
    ) {
      logInfo.referral_store_search_query_id = this.searchRequestId;
    }
    this._logTracker(logInfo, appId);
  }

  postStoreCloseEvent(data) {
    const logInfo = this._appendSharedFields(data);
    this.postData(logInfo, { appendAccountInfo: true });
  }

  _logTracker(logInfo, pseudoId = '0') {
    const { event_name: eventName } = logInfo;
    const eventId = this._getEventIdentifier(eventName, pseudoId);
    let targetLogs = null;
    let logIndex = null;

    if (this.queuedEvents[eventId] === undefined) {
      this.queuedEvents[eventId] = [];
    }
    targetLogs = this.queuedEvents[eventId];

    logIndex = targetLogs.findIndex(log => log.event_name === eventName);
    if (logIndex === -1) {
      targetLogs.push(logInfo);
    } else {
      targetLogs.splice(logIndex, 1, logInfo);
    }
    // sometimes there are additional install_failed events,
    // especially when clicking on the Stop button for multiple times
    // so make sure length is greater than 1
    if (this._isAnEndingEvent(eventName)) {
      if (targetLogs.length > 1) {
        targetLogs.forEach(log => {
          this.postData(log, { appendAccountInfo: true });
        });
      }
      delete this.queuedEvents[eventId];
    }
  }

  _appendSharedFields(data) {
    const connectionType =
      deviceUtils.information.connection.type === 'wifi' ? 'wifi' : 'mobile';
    const { event_name: eventName } = data;
    const logInfo = Object.assign(data, {
      store_version: this.storeVersion,
      device_utc: Date.now(),
      connection_type: connectionType,
    });

    if (eventName.endsWith('init') || eventName === 'store_close') {
      logInfo.user_journey = [...this.browsedHistory];
      this._clearUserJourney();
    }

    return { ...logInfo };
  }

  _getEventIdentifier(eventName, pseudoId) {
    let eventType = null;
    let eventId = null;

    if (eventName.startsWith(EVENT_TYPE_INITIALS.STORE_SEARCH)) {
      eventType = EVENT_TYPE_INITIALS.STORE_SEARCH;
    } else if (eventName.startsWith(EVENT_TYPE_INITIALS.STORE_APP_VIEW)) {
      eventType = EVENT_TYPE_INITIALS.STORE_APP_VIEW;
    } else if (eventName.startsWith(EVENT_TYPE_INITIALS.STORE_APP)) {
      /*
       * eventType for app actions can be store_app_install
       * (store_app_install_init/done/fail or store_app_install_cancel_init)
       *  or store_app_update
       * (store_app_update_init/done/fail or store_app_update_cancel_init)
       */
      eventType = eventName
        .split('_')
        .slice(0, 3)
        .join('_');
    }

    eventId = `${eventType}_${pseudoId}`;
    return eventId;
  }

  _isAnEndingEvent(eventName) {
    return eventName.includes('done') || eventName.includes('fail');
  }

  _clearUserJourney() {
    this.browsedHistory = [];
  }
}

export default new AnalyticsHelper();
