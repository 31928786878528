import { deviceUtils } from './device-utils';

class Utils {
  constructor() {
    // For tracing the performance while developing.
    this._debug = false;
    this._cacheCommsInfo = JSON.stringify(deviceUtils.information.connection);
  }

  checkParameters() {
    let newCommsInfo = JSON.stringify(deviceUtils.information.connection);
    if (this._cacheCommsInfo !== newCommsInfo) {
      this.rebootRequest();
    }
  }

  rebootRequest() {
    var rebootEvent = new CustomEvent('reboot-needed', {
      detail: 'device-info-change',
    });

    window.dispatchEvent(rebootEvent);
  }

  getUrlInfo(url) {
    if (url) {
      return new URL(url);
    }
  }

  performanceMeasure(name) {
    switch (name) {
      case 'REMOTE_STORE':
        performance.measure(name, name, name);
        break;
      default:
        const start = performance.getEntriesByName(`${name}_START`);
        const end = performance.getEntriesByName(`${name}_END`);

        if (start.length && end.length) {
          performance.measure(name, name + '_START', name + '_END');
        } else {
          this.dump(`[Measure Failed] ${name}`);
        }
        break;
    }
  }

  performanceLogs(timestamp) {
    const measures = [
      'REMOTE_STORE',
      'REQUEST_DEVICE',
      'REQUEST_TOKEN',
      'FETCH_APPS_CAT',
      'REQUEST_INSTALLED',
      'REMOTE_PAGE_RENDERING',
    ];
    let dataArray = null;
    let loadApps = null;
    let loadCategories = null;
    let loadSearchApp = null;
    let startTime = 0;
    let timing = 0;
    let duration = 0;
    let findEntity = str => {
      let resources = performance.getEntriesByType('resource');
      return resources.find(element => element.name.indexOf(str) > 0);
    };

    measures.forEach(key => this.performanceMeasure(key));
    dataArray = performance.getEntriesByType('measure');

    loadApps = findEntity('/apps?');
    loadApps && dataArray.push(loadApps);
    loadCategories = findEntity('/categories?');
    loadCategories && dataArray.push(loadCategories);
    loadSearchApp = findEntity('/graphql');
    loadSearchApp && dataArray.push(loadSearchApp);

    // print the logs
    this.dump('******** [Remote] Performance Testing ********');
    dataArray.forEach(data => {
      if (data.name === 'REMOTE_STORE') {
        startTime = data.startTime;
      }
      timing = (data.startTime - startTime).toFixed(2);
      duration = data.duration.toFixed(2);
      if (data.name === 'REMOTE_STORE') {
        this.dump(
          `[${
            data.name
          }] start: ${timing} ms, duration: ${duration}, timestamp: ${
            this.remoteStartTime
          }`
        );
      } else {
        this.dump(
          `[${
            data.name.split('?')[0]
          }] start: ${timing} ms, duration: ${duration}`
        );
      }
    });
    this.dump('******** [Remote] End ********');
    this.dump('First view is ready at', timestamp);
  }

  dump(...args) {
    this._debug && console.warn(...args);
  }
}

export const utils = new Utils();

export const isVersionHigher = (oldVersion, newVersion) => {
  let oldVerDigits = oldVersion.split('.').map(digit => parseInt(digit, 10));
  let newVerDigits = newVersion.split('.').map(digit => parseInt(digit, 10));

  // filling 0 if number of the digits are not the same
  const missingDigit = Math.abs(oldVerDigits.length - newVerDigits.length);
  if (missingDigit > 0) {
    if (oldVerDigits.length > newVerDigits.length) {
      newVerDigits = newVerDigits.concat(Array(missingDigit).fill(0));
    } else {
      oldVerDigits = oldVerDigits.concat(Array(missingDigit).fill(0));
    }
  }

  for (let i = 0; i < oldVerDigits.length; i++) {
    const oldDigit = oldVerDigits[i];
    const newDigit = newVerDigits[i];
    if (newDigit > oldDigit) {
      return true;
    } else if (oldDigit > newDigit) {
      return false;
    }
  }
  return false;
};

export const getHigherVersion = (oldVersion, newVersion) => {
  if (isVersionHigher(oldVersion, newVersion)) {
    return newVersion;
  }
  return oldVersion;
};

export const clamp = (number, min, max) => {
  return Math.min(Math.max(number, min), max);
};
