import {
  RequestBookmarksCommand,
  CheckRebootCommand,
} from 'kaistore-post-messenger/src/commands';
import { MessageSender } from 'web-message-helper';
import { route } from 'preact-router';
import { mozAppEvent as MOZ_APP_EVENT } from 'kaistore-post-messenger/lib/constants';
import PaymentHelper from './helper/payment-helper';
import { isVersionHigher } from './utils';
import { PATH, SPECIAL_CATE_CODE } from './constant';
import Application from './model/Application';
import AppListHelper from '@/helper/applist-helper';
import CacheHelper from '@/helper/cache-helper';

const DEFAULT_PAGE_SIZE = 20;
class AppStore {
  constructor() {
    this.init = () => {
      // Make KaiStore can go back APP detail page after user login.
      this.preparedDownloadApp = null;
      this.applications = new Map();

      this.kaipayUpdated = false;
      // this.purchasedApps should be a Set
      this.purchasedApps = new Set();
      this.categories = {
        all: [],
        carrier: [],
        recommended: [],
        static: [],
      };
      this.updatableApps = {
        system: new Map(),
        remote: new Map(),
      };
      this.updateAllInProcess = {
        system: false,
        remote: false,
      };
      this._searchRecord = {
        apps: [],
        keyword: '',
        errorMsg: '',
        focusTarget: 'input',
        searchLock: null,
      };
      this.bookmarksMap = new Map();
      /*
      *  key: cateCode
      *  value: {
      *    pageNum: page last fetched,
      *    (pageSize): use APPS_PER_PAGE; won't store in the Map;
      *    isLastPage: true if no more apps to fetch on that cate,
      *  }
      * special key 'SPECIAL_CATE_CODE.ALL' for getting all apps
      * usage: fallback to get all apps for handling failed GraphQL request for inline activity
      */
      this.catePagination = new Map();
      CacheHelper.prepareCachedItem();
    };

    this.init();
  }

  get apps() {
    return [...this.applications.values()];
  }

  get allRemoteItems() {
    return this.apps.filter(app => app.isInRemoteList && !app.isInternal);
  }

  get allRemoteApps() {
    return this.apps.filter(
      app => app.isInRemoteList && !app.isBookmark && !app.isInternal
    );
  }

  get bookmarks() {
    return this.apps.filter(app => app.isBookmark);
  }

  get installedApps() {
    return this.apps.filter(app => app.isInstalled);
  }

  get hasUpdatableApps() {
    return this.updatableApps.system.size + this.updatableApps.remote.size > 0;
  }

  get updateAllRebootNeeded() {
    for (let [manifestURL] of this.updatableApps.system.entries()) {
      const application = this.findAppByManifest(manifestURL);
      if (application.isRebootNeeded) {
        return true;
      }
    }
    return false;
  }

  get popNextUpdatePendingSystemApp() {
    const manifest = this.updatableApps.system.keys().next().value;
    if (!manifest) {
      return false;
    }
    this.updatableApps.system.delete(manifest);
    return manifest;
  }

  get coreAppUpdateEnabled() {
    return window.deviceInfos.get('store.coreUpdate.enable');
  }

  set searchState(state) {
    this._searchRecord = state;
  }

  get searchState() {
    return this._searchRecord;
  }

  get allCategories() {
    return this.categories.all;
  }

  resetStore() {
    this.init();
  }

  initHelpers(locale) {
    this.appListHelper = new AppListHelper(locale);
    this.paymentHelper = new PaymentHelper();
  }

  initPurchasedApps() {
    return (
      this.paymentHelper
        .getAllPurchased()
        // retry one more time if api failed
        .catch(error => {
          console.error(
            'Cannot get purchased Apps from server. Will try one more time',
            error
          );
          return this.paymentHelper.getAllPurchased();
        })
        // if both tries failed, will start the store without purchased apps.
        .then((products = []) => {
          // Get apps from products only.
          const purchasedAppIds = products
            .filter(product => product.type === 1)
            .map(product => product.related_good_id);
          this.purchasedApps = new Set(purchasedAppIds);
        })
    );
  }

  fetchComboList(hasToken) {
    let promise = null;
    if (CacheHelper.isCacheValid) {
      const { categories, comboApps } = CacheHelper.item;
      promise = () => {
        return Promise.resolve({ categories, comboApps, isFromCache: true });
      };
    } else if (hasToken) {
      promise = () => {
        return this.appListHelper.fetchComboList();
      };
    } else {
      console.error('No restricted token for fetching combo list.');
      return Promise.reject();
    }

    return promise().then(result => {
      performance.mark('FETCH_APPS_CAT_END');
      const { categories, comboApps, isFromCache } = result;
      if (!isFromCache) {
        CacheHelper.setValidCacheItem(categories, comboApps);
      }
      this.categories = categories;
      this.initComboApps(comboApps);
      return result;
    });
  }

  getAppsByCate(cateCode) {
    if (cateCode === SPECIAL_CATE_CODE.RECOMMENDED) {
      // TODO: could only do this sorting once
      return this.allRemoteItems
        .filter(app => app.remoteInfo.recommended_index)
        .sort(
          (app1, app2) =>
            app1.remoteInfo.recommended_index -
            app2.remoteInfo.recommended_index
        );
    }
    return this.allRemoteItems.filter(
      app => app.remoteInfo.category === cateCode && !app.isNativeApp
    );
  }

  getPaginationByCate(cateCode) {
    const allCatePagination = this.catePagination.get(SPECIAL_CATE_CODE.ALL);
    if (allCatePagination) {
      // No need to fetch if all apps have been fetched
      return allCatePagination;
    }
    const pagination = this.catePagination.get(cateCode);
    if (!pagination) {
      return cateCode === SPECIAL_CATE_CODE.ALL
        ? {
            // omit pageNum and pageSize here to get all apps
            isLastPage: false,
          }
        : {
            pageNum: 1,
            pageSize: DEFAULT_PAGE_SIZE,
            isLastPage: false,
          };
    }
    return pagination.isLastPage
      ? // do not alter the pagination object if it has reached the last page of the category
        pagination
      : {
          ...pagination,
          pageNum: pagination.pageNum + 1,
          pageSize: DEFAULT_PAGE_SIZE,
        };
  }

  setPaginationByCate(cateCode, pagination) {
    this.catePagination.set(cateCode, pagination);
  }

  isCarrierCate(cateCode) {
    return this.categories.carrier.map(cate => cate.code).includes(cateCode);
  }

  fetchAppListByCate(cateCode) {
    // Filter out request to fetch carrier and recommended apps since we have received them via combo API
    if (
      cateCode === SPECIAL_CATE_CODE.RECOMMENDED ||
      this.isCarrierCate(cateCode)
    ) {
      return Promise.resolve([]);
    }
    const {
      isLastPage: wasLastPage,
      pageNum,
      pageSize,
    } = this.getPaginationByCate(cateCode);
    if (!wasLastPage) {
      return this.appListHelper
        .fetchAppListByCate({ cateCode, pageNum, pageSize })
        .then(({ apps, isLastPage }) => {
          this.initRemoteApps(apps);
          this.syncBookmarkStates();
          this.setPaginationByCate(cateCode, { pageNum, isLastPage });
          this.publish('appstore:change');
          return apps;
        });
    }
    return Promise.resolve([]);
  }

  // FIXME: keep initComboApps and initRemoteApps for now; combine later.
  initComboApps(apps) {
    this.formatRemoteApps(apps);
  }

  initRemoteApps(remoteApps) {
    this.formatRemoteApps(remoteApps);
  }

  formatRemoteApps(remoteApps) {
    remoteApps.forEach(app => {
      if (!app.manifest_url) {
        console.warn(`Malformed app ${app.name} without manifest_url. Skip.`);
        return;
      }
      const manifestURL = app.manifest_url;
      const existedApplication = this.findAppByManifest(manifestURL);
      const application = existedApplication || new Application(manifestURL);

      if (application.setRemoteInfo(app)) {
        if (this.purchasedApps.has(application.id)) {
          application.purchased = true;
        }
        if (existedApplication) {
          // make early pushed installed app follows remote order
          this.applications.delete(manifestURL);
        }
        this.applications.set(manifestURL, application);
      } else {
        console.warn('malformed app information for', app);
      }

      this.checkForUpdate(application);
    });
  }

  syncInstalledApps(installedApps) {
    if (Object.keys(installedApps).length === 0) {
      return;
    }

    Object.keys(installedApps).forEach(manifestURL => {
      const mozAPP = installedApps[manifestURL];
      const newManifestURL = this._getCoreManifestURL(mozAPP);

      const existedApplication = this.findAppByManifest(newManifestURL);
      const application = existedApplication || new Application(newManifestURL);

      application.mozAPP = mozAPP;
      this.syncUpdatableApp(application);

      this.checkForUpdate(application);

      this.applications.set(manifestURL, application);
    });
  }

  checkForUpdate(application) {
    // update of internal apps would be handled in client automatically
    if (
      !application.isInstalled ||
      application.isInternal ||
      application.updateChecked
    ) {
      return;
    }
    const checkAllInstalledApps = this.coreAppUpdateEnabled;
    const isInstalledRemoteApp =
      application.isInstalled && application.isInRemoteList;
    if (
      (checkAllInstalledApps && application.isInstalled) ||
      (!checkAllInstalledApps && isInstalledRemoteApp)
    ) {
      application.updateChecked = true;
      application.checkUpdate();
    }
  }

  // sync early updatable apps before waiting result from checkUpdate()
  // to minizie the change that update tab flashing in
  syncUpdatableApp(application) {
    const mozAPP = application.mozAPP;
    /**
     * If the application is still downloading or being cancelled during
     * downloading, there would be no manifest field
     */
    const hasBothManifest = mozAPP.manifest && mozAPP.updateManifest;
    if (
      !hasBothManifest ||
      !isVersionHigher(
        mozAPP.manifest.version,
        mozAPP.updateManifest.version
      ) ||
      application.isInternal
    ) {
      return;
    }
    const newManifestURL = this._getCoreManifestURL(mozAPP);
    if (application.core) {
      this.updatableApps.system.set(newManifestURL);
    } else {
      this.updatableApps.remote.set(newManifestURL);
    }
    application.updateState({ updatable: true });
  }

  syncBookmarks() {
    return new Promise((resolve, reject) => {
      MessageSender.send(new RequestBookmarksCommand(), (success, detail) => {
        if (success) {
          Object.keys(detail).forEach(key => {
            this.bookmarksMap.set(key, detail[key]);
          });
          resolve({ bookmarkDBSupported: true });
        } else {
          resolve({ bookmarkDBSupported: false });
        }
      });
    });
  }

  syncBookmarkStates() {
    this.bookmarks.forEach(bookmark => {
      const url = bookmark.info.url;
      bookmark.state.pinned = this.bookmarksMap.has(url);
    });
  }

  updateAll(type, enable = true) {
    const apps = this.updatableApps[type];
    if (apps && apps.size > 0) {
      this.updateAllInProcess[type] = enable;
      apps.forEach((value, manifest) => {
        const app = this.findAppByManifest(manifest);
        app.updateState({ updatePending: enable });
      });
    }
    if (type === 'system') {
      const nextManifest = this.popNextUpdatePendingSystemApp;
      if (nextManifest) {
        route(PATH.PAGE.URL({ manifest: nextManifest, batchUpdating: true }));
      }
    } else if (type == 'remote' && this.updatableApps.remote.size > 0) {
      const nextManifest = this.updatableApps.remote.keys().next().value;
      const app = this.findAppByManifest(nextManifest);
      if (app) {
        app.update(true);
      }
    }
  }

  handleUpdate(manifest, mozAppEvent) {
    switch (mozAppEvent) {
      case MOZ_APP_EVENT.ON_DOWNLOAD_APPLIED:
      case MOZ_APP_EVENT.ON_DOWNLOAD_SUCCESS:
      case MOZ_APP_EVENT.ON_DOWNLOAD_ERROR:
        if (this.updateAllInProcess.remote) {
          this.updatableApps.remote.delete(manifest);
          const nextManifest = this.updatableApps.remote.keys().next().value;
          const app = this.findAppByManifest(nextManifest);
          if (app) {
            app.update();
          } else {
            this.updateAllInProcess.remote = false;
            this.publish('appstore:change');
          }
        }
        break;
      default:
        break;
    }
  }

  findAppByName(name) {
    return this.apps.find(app => app.name === name);
  }

  findItemById(id) {
    return this.allRemoteItems.find(item => item.id === id);
  }

  findAppByManifest(manifestURL) {
    return this.applications.get(manifestURL);
  }

  findBookmarkByUrl(url) {
    return this.bookmarks.find(bookmark => bookmark.info.url === url);
  }

  _getCoreManifestURL(mozAPP) {
    const hasUpdateURL = mozAPP.manifest && mozAPP.manifest.updateURL;
    return hasUpdateURL ? mozAPP.manifest.updateURL : mozAPP.manifestURL;
  }

  publish(eventName) {
    const evt = new CustomEvent(eventName);
    window.dispatchEvent(evt);
  }

  rebootIfNeeded() {
    MessageSender.send(new CheckRebootCommand());
  }

  clearEmptyApplication(manifestURL) {
    this.applications.delete(manifestURL);
    this.updatableApps.system.delete(manifestURL);
    this.updatableApps.remote.delete(manifestURL);
  }
}

export default new AppStore();
