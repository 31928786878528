// TODO: was shared by AppsPanel and SearchPanel but only used by SearchPanel now;
//  should refactor to remove unnecessary code
import 'scss/AppListView.scss';

import React, { PureComponent } from 'react';
import AppBrick from './AppBrick';
import RichContentHelper from '@/helper/richcontent-helper';
import { loadImage } from '@/helper/image-loader';
import UserInterfaceHelper from '@/helper/user-interface-helper';

const PREFIX = 'LISTVIEW';
const LAZY_LOAD_NUM = 5;

class AppListView extends PureComponent {
  constructor(props) {
    super();
    const apps = this.getApps(props);
    const { tabOrder, appOrder } = UserInterfaceHelper.lastAppsviewIndex;
    const readLastIndex =
      appOrder && props.order === tabOrder && appOrder <= apps.length;

    this.navDirection = 1;

    this.state = {
      currentBrickIndex: readLastIndex ? appOrder : 0,
      apps: apps,
      initialized: props.isCurrentList,
      lastLoadedBrickIndex: readLastIndex ? appOrder : 0,
    };
  }

  componentWillUnmount() {
    if (this.props.isCurrentList) {
      UserInterfaceHelper.lastAppsviewIndex.appOrder = this.state.currentBrickIndex;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const currentCategory = this.props.category;
    if (
      prevProps.category !== currentCategory ||
      prevProps.apps.length !== this.props.apps.length
    ) {
      this.setState({
        apps: this.getApps(),
      });
    }
    if (prevState.currentBrickIndex !== this.state.currentBrickIndex) {
      if (this.state.currentBrickIndex > this.state.lastLoadedBrickIndex) {
        this.setState({ lastLoadedBrickIndex: this.state.currentBrickIndex });
      }
      this._focus();
    }
    if (this.props.isCurrentList && this.state.initialized === false) {
      this.setState({
        initialized: true,
      });
    }
    if (
      (prevState.initialized === false && this.state.initialized === true) ||
      (prevState.apps.length !== this.state.apps.length &&
        this.props.isCurrentList &&
        !UserInterfaceHelper.popupVisible)
    ) {
      this._focus();
    }
  }

  getApps(props = null) {
    const { category, apps } = props ? props : this.props;
    if (typeof category === undefined) {
      return [];
    }
    if (category === 'all' || category === 'search') {
      return apps;
    }
    if (category === 'recommended') {
      // When recommended_index > 0, we will show this app in the recommendation tab,
      // and the list will also be sorted by recommended_index.
      return apps
        .filter(app => !!app.remoteInfo['recommended_index'])
        .sort(this._sortRecommendationApps);
    }

    return apps.filter(app => {
      return app.remoteInfo.category === category && !app.core;
    });
  }

  _sortRecommendationApps(firstApp, secondApp) {
    return (
      firstApp.remoteInfo['recommended_index'] -
      secondApp.remoteInfo['recommended_index']
    );
  }

  _nav(move, e) {
    const searchBar = document.querySelector('.SearchBar');
    const brickNumber = this.state.apps.length;
    let nextIndex = parseInt(this.state.currentBrickIndex, 10) + move;
    this.navDirection = move;

    if (nextIndex < 0) {
      searchBar && searchBar.focus();
      this.setState({ currentBrickIndex: 0 });
    } else if (nextIndex < brickNumber) {
      nextIndex %= brickNumber;
      this.setState({ currentBrickIndex: nextIndex });
    }
    e.stopPropagation();
  }

  handleKeydown = e => {
    switch (e.key) {
      case 'ArrowUp':
        this._nav(-1, e);
        break;
      case 'ArrowDown':
        this._nav(1, e);
        break;
      case 'ArrowRight':
      case 'ArrowLeft':
      case 'Accept':
      case 'Enter':
      default:
        // do nothing, pass to parent.
        break;
    }
  };

  _focus() {
    const currentIndex = this.state.currentBrickIndex;
    const brick = this.element.querySelectorAll('.brick')[currentIndex];
    const currentAppId = this.state.apps[currentIndex].id;

    if (brick) {
      brick.focus();
      brick.scrollIntoView({
        // set vertical alignment to bottom when moving upwards and vice versa
        // to ensure the component could be shown completely
        block: this.navDirection > 0 ? 'end' : 'start',
      });
    }
  }

  handleFocus = e => {
    this._focus();
  };

  handleBrickFocus = () => {
    if (RichContentHelper.enable) {
      loadImage(
        this.element.querySelectorAll('.brick')[this.state.currentBrickIndex],
        'bg'
      );
    }
  };

  render() {
    const { apps, lastLoadedBrickIndex } = this.state;
    const category = this.props.category;
    const hasProgressBar = category !== 'recommended';
    var renderApps = [];

    if (this.state.initialized) {
      renderApps = apps.slice(0, lastLoadedBrickIndex + LAZY_LOAD_NUM);
    }
    return (
      <div
        className="AppListView"
        tabIndex="1"
        onKeyDown={this.handleKeydown}
        onFocus={this.handleFocus}
        ref={target => {
          this.element = target;
        }}
      >
        <div className="container">
          {renderApps.length > 0 &&
            renderApps.map((result, index) => (
              <AppBrick
                key={PREFIX + '-' + result.id}
                data-id={result.id}
                appOrder={index}
                app={result.brickInfo}
                mozAPP={result.mozAPP}
                hasProgressBar={hasProgressBar}
                focusHandler={this.handleBrickFocus}
              />
            ))}
          {renderApps.length === 0 && <div className="loader" />}
        </div>
      </div>
    );
  }
}

export default AppListView;
