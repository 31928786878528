import {
  PINBookmarkCommand,
  UnPINBookmarkCommand
} from 'kaistore-post-messenger/src/commands';
import { Bookmark } from 'kaistore-post-messenger/src/models';
import { loggerHelper, bookmarkEvent } from './logger-helper';
import { MessageSender } from 'web-message-helper';

export const openWebsite = app => {
  const url = app.info.url ? app.info.url : app.manifestURL;
  loggerHelper.postBookmarkData(bookmarkEvent.OPEN, app.id, app.info);
  window.open(url, '_blank', 'remote=ture');
};

export const pinWebsite = app => {
  const { icon, url, localized } = app.info;

  const command = new PINBookmarkCommand({
    detail: new Bookmark({
      url: url,
      icon: icon,
      name: localized.name
    })
  });
  MessageSender.send(command, success => {
    if (success === true) {
      loggerHelper.postBookmarkData(bookmarkEvent.PIN, app.id, app.info);
    }
  });
};

export const unPinWebsite = app => {
  const command = new UnPINBookmarkCommand({
    detail: {
      url: app.info.url
    }
  });
  MessageSender.send(command);
};
