export const normalizedURL = url => {
  return new URL(url).href;
};

export const isValidURL = URL => {
  const regexQuery =
    '^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$';
  const url = new RegExp(regexQuery, 'i');
  return url.test(URL);
};

export const localeCode = info => {
  let lang = navigator.mozL10n.language.code || '';
  const defaultLocale = info.default_locale;
  if (!info.locales.hasOwnProperty(lang)) {
    if (info.locales.hasOwnProperty(defaultLocale)) {
      lang = info.default_locale;
    } else {
      lang = 'en-US';
    }
  }
  return lang;
};

export const trimBgs = bgs => {
  if (bgs && Object.keys(bgs).length > 0) {
    return bgs[Object.keys(bgs)[0]];
  }
  return null;
};

export const trimIcons = icons => {
  if (!icons) {
    return null;
  }
  const defaultSize = '56';
  const sizes = Object.keys(icons);
  if (sizes.length === 0) {
    return null;
  }

  return icons[defaultSize] || icons[sizes[0]];
};

export const trimLocales = (locales, lang) => {
  if (!locales || !lang) {
    return {};
  }
  /*
   * XXX: Since there is a limitation from graphql (not allowed having "-" in
   * attribute), to prevent the page cannot get the locale properly, let's
   * check lang (e.g. en-US) first. If locale is not found, then check lang
   * that replaces "-" with "_" (e.g. en_US).
   */
  return (
    locales[lang] ||
    locales[lang.replace(/-/g, '_')] ||
    Object.values(locales)[0] ||
    {}
  );
};

export const getLocales = remoteInfo => {
  const { locales } = remoteInfo;
  // XXX: Handle what BACKEND FAILED to handle, fallback to the old way
  if (locales) {
    return trimLocales(locales, localeCode(remoteInfo));
  }
  const { display: name, description, subtitle } = remoteInfo;
  return { name, description, subtitle };
};

export const getSize = byte => {
  const hundredKB = 102400;
  return byte < hundredKB
    ? `${(byte / 1024).toFixed(1)}KB`
    : `${(byte / 1024 / 1024).toFixed(1)}MB`;
};
