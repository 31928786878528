import 'scss/SearchPanel.scss';

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SoftKeyStore from 'soft-key-store';
import { route } from 'preact-router';

import { PATH } from '@/constant';
import SearchHelper from '@/helper/search-helper';
import AppStore from '@/app-store';
import Application from '@/model/Application';
import AppListView from '@/component/AppListView';
import analyticsHelper from '@/helper/analytics-helper';

class SearchPanel extends Component {
  constructor(props) {
    super(props);
    this.searchApps = AppStore.searchState.apps;
    this.state = AppStore.searchState;
  }

  _focus() {
    const { focusTarget } = this.state;

    switch (focusTarget) {
      case 'list':
        this.activeList();
        break;
      case 'input':
      default:
        this.activeInput();
        break;
    }
  }

  activeInput() {
    const focusElement = ReactDOM.findDOMNode(this.searchInput);
    const input = focusElement.querySelector('input');
    const len = input.value.length;

    requestAnimationFrame(() => {
      this.setState({ focusTarget: 'input' }, this.updateSoftKey);
      focusElement.classList.add('active');
      input.focus();
      // Move cusor to the end of keywords
      input.setSelectionRange(len, len);
    });
  }

  activeList() {
    const blurElement = ReactDOM.findDOMNode(this.searchInput);
    const focusElement = ReactDOM.findDOMNode(this.searchList);

    if (focusElement) {
      requestAnimationFrame(() => {
        this.setState({ focusTarget: 'list' }, this.updateSoftKey);
        blurElement.classList.remove('active');
        focusElement.classList.add('active');
        focusElement.focus();
      });
    }
  }

  // Handle keydown events
  handleKeydown(e) {
    switch (e.key) {
      case 'Enter':
        this.handleEnter(e);
        break;
      case 'ArrowDown':
        this.activeList();
        break;
      case 'EndCall':
      case 'BrowserBack':
      case 'Backspace':
      case 'Escape':
        this.resetState(() => {
          route(PATH.APPS.URL());
        });
        e.preventDefault();
        e.stopPropagation();
        break;
      default:
        break;
    }
  }

  handleEnter(evt) {
    const { focusTarget } = this.state;
    const { target } = evt;

    if (focusTarget === 'list') {
      const { id, appOrder } = target.dataset;

      AppStore.searchState = this.state;
      this.gotoPage(id, parseInt(appOrder, 10));
    } else {
      /**
       * Using lock to prevent sending multiple search requests in a short time
       * and unlock it after search result is back
       **/
      if (this.state.searchLock === null) {
        this.setState({
          ...this.state,
          keyword: target.value,
          searchLock: true,
        });
        this.searchAppsByValue(target.value);
        this.toggleSearchButton();
      }
    }
  }

  // Go to an app detail page
  gotoPage(id, appOrder) {
    if (!id) {
      return;
    }
    route(PATH.PAGE.URL({ id, appOrder, entry: 'search' }));
  }

  searchAppsByValue(keyword) {
    const { locales } = this.props;
    analyticsHelper.postSearchEvent({
      event_name: 'store_search_init',
      keyword,
    });
    const searchHelper = new SearchHelper();
    searchHelper.searchApps(keyword, locales).then(
      result => {
        if (result.data) {
          this.resetState(() => {
            result.data.forEach(app => {
              this.formatApplication(app);
            });
            this.setState(
              {
                apps: this.searchApps,
                focusTarget: 'list',
                keyword,
                errorMsg: '',
                searchLock: null,
              },
              () => {
                this._focus();
                AppStore.searchState = this.state;
              }
            );
          });
        } else {
          this.setState({
            apps: [],
            focusTarget: 'input',
            keyword,
            errorMsg: 'search-no-result',
            searchLock: null,
          });
        }
        this.toggleSearchButton();
        analyticsHelper.searchRequestId = result.query_id;
        analyticsHelper.postSearchEvent({
          event_name: 'store_search_done',
          keyword,
        });
      },
      err => {
        this.setState({
          apps: [],
          focusTarget: 'input',
          keyword,
          errorMsg: 'search-unavailable',
          searchLock: null,
        });
        console.error(err);
        this.toggleSearchButton();
        analyticsHelper.postSearchEvent({
          event_name: 'store_search_failed',
          error_detail: {
            error_code: JSON.stringify(err.error.status),
          },
          keyword,
        });
      }
    );
  }

  resetState(callback = null) {
    this.searchApps = [];
    this.setState(
      {
        apps: [],
        focusTarget: 'input',
        keyword: '',
        errorMsg: '',
        searchLock: null,
      },
      () => {
        AppStore.searchState = this.state;
        if (callback) callback();
      }
    );
  }

  updateSoftKey() {
    const { focusTarget } = this.state;
    const option = {
      left: '',
      center: focusTarget === 'list' ? 'select' : 'search',
      right: '',
    };
    SoftKeyStore.register(option, this.element);
  }

  toggleSearchButton() {
    const searchBtn = document.querySelector('#software-keys-center');
    if (searchBtn.hasAttribute('disabled')) {
      searchBtn.removeAttribute('disabled');
    } else {
      searchBtn.setAttribute('disabled', true);
    }
  }

  componentDidMount() {
    this.element.addEventListener('keydown', this.handleKeydown.bind(this));
    this._focus();
    analyticsHelper.saveViewedTab('search');
  }

  formatApplication(fromSearch) {
    const app = fromSearch;
    const existedApplication = AppStore.findAppByManifest(app.manifest_url);

    if (existedApplication) {
      const { remoteInfo } = existedApplication;

      // Set remoteInfo by searched data while it doesn't exist.
      if (!remoteInfo) {
        this.setRemoteInfoBySearch(existedApplication, app);
      }
      this.searchApps.push(existedApplication);
    } else {
      console.warn('The search app cannot be found in app-list.', app);
      const application = new Application(app.manifest_url);

      if (this.setRemoteInfoBySearch(application, app)) {
        this.searchApps.push(application);
        AppStore.applications.set(app.manifest_url, application);
      }
    }
  }

  setRemoteInfoBySearch(application, appInfo) {
    // translate format for Application model
    const translatedApp = {
      id: appInfo.id,
      version: appInfo.version,
      manifest_url: appInfo.manifest_url,
      icons: {},
      bgs: {},
      theme: appInfo.theme,
      type: appInfo.type,
      paid: appInfo.paid,
      created_at: appInfo.release_date * 1000000,
      display: appInfo.name,
      subtitle: appInfo.summary,
      description: appInfo.description,
    };

    if (appInfo.thumbnail_url) {
      translatedApp.icons = {
        '56': appInfo.thumbnail_url,
      };
    }

    if (appInfo.background_url) {
      translatedApp.bgs = {
        '168': appInfo.background_url,
      };
    }
    return application.setRemoteInfo(translatedApp);
  }

  render() {
    const { apps, keyword, errorMsg, searchLock } = this.state;

    return (
      <div
        className="SearchPanel"
        tabIndex="1"
        ref={target => {
          this.element = target;
        }}
      >
        <div
          className="SearchBar"
          tabIndex="1"
          onFocus={this.activeInput.bind(this)}
          ref={target => {
            this.searchInput = target;
          }}
        >
          <ul>
            <li>
              <input type="text" dir="auto" value={keyword} />
            </li>
          </ul>
        </div>
        {searchLock && (
          <div className="SearchMsgBox">
            <div className="Loader" />
          </div>
        )}
        {apps.length > 0 && (
          <div className="SearchListBox">
            <AppListView
              ref={target => {
                this.searchList = target;
              }}
              apps={apps}
              category="search"
              order="0"
              isCurrentList="false"
            />
          </div>
        )}
        {errorMsg && (
          <div className="SearchMsgBox">
            <p data-l10n-id={errorMsg} />
          </div>
        )}
      </div>
    );
  }
}

export default SearchPanel;
