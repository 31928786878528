// FIXME: the loading part of this panel is not in use anymore,
// should remove related css and refactor to show no available app
import React, { Component } from 'react';
import SoftKeyStore from 'soft-key-store';

import 'scss/LoadingPanel.scss';

class LoadingPanel extends Component {
  // constructor(props) {
  //   super(props);
  // }

  componentDidMount() {
    this._focus();
  }

  _focus() {
    var option = {
      center: '',
      right: '',
    };
    SoftKeyStore.register(option, this.element);
    this.element.focus();
  }

  render() {
    const { noAvailableAPP } = this.props;
    var statusID = navigator.onLine ? 'loading' : 'no-internet-with-tips';
    var progressClasses = navigator.onLine ? 'loading-bar play' : 'loading-bar';

    if (noAvailableAPP && navigator.onLine) {
      statusID = 'no-available-app';
      progressClasses = 'loading-bar';
    }

    return (
      <div
        className="LoadingPanel"
        tabIndex="1"
        ref={element => {
          this.element = element;
        }}
      >
        <h1>
          <span data-l10n-id="store" />
        </h1>
        <div className="loading-box">
          <div className={progressClasses} />
        </div>
        <div className="content">
          <p data-l10n-id={statusID} />
        </div>
      </div>
    );
  }
}

export default LoadingPanel;
