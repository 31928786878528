import '../../../scss/component/divider.scss';

import React from 'react';

const Divider = React.memo(function Divider(props) {
  const { l10nId, number } = props;
  return (
    <div className="divider">
      <div data-l10n-id={l10nId} className="title" />
      <div className="indicator">
        <span>{number}</span>
      </div>
    </div>
  );
});

export default Divider;
