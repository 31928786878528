import React from 'react';

const CategoryTabs = React.forwardRef(
  ({ categories, currentCateIndex }, ref) => (
    <gaia-tabs class="h3" underline="child" position="top" ref={ref.box}>
      {categories.map((cate, index) => (
        <div
          key={cate.displayName}
          tabIndex="1"
          className={`tab ${currentCateIndex === index ? 'selected' : ''}`}
          ref={ref[cate.code]}
        >
          <span>{cate.displayName}</span>
        </div>
      ))}
    </gaia-tabs>
  )
);

// fix ESLint false positive error
CategoryTabs.displayName = 'CategoryTabs';

export default CategoryTabs;
