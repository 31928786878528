import 'scss/AppBrick.scss';

import React, { Component } from 'react';
import ProgressBar from 'progress-bar';
import Marquee from './Marquee';
import { isOverflown } from '@/helper/user-interface-helper';
import { loadImage } from '@/helper/image-loader';
import RichContentHelper from '@/helper/richcontent-helper';

import { deviceUtils } from '@/device-utils';

/*
* This is a magic number for fitting UX.
* In general case, network speed is stable and fast,
* and average app's size is between 2MB ~ 5MB.
* So first progress event from gecko might be smaller than 1%,
* then next event will be finished event.
* So we made `PROGRESS_BASE_LEVEL` as mock progress at first event.
*/

const PROGRESS_BASE_LEVEL = 13;

const _ = navigator.mozL10n.get;

class AppBrick extends Component {
  state = {
    titleOverflow: false,
    showProgressBar: false,
    progressState: 0,
    progressInfinite: true,
    isFocused: false,
  };
  constructor(props) {
    super(props);
    this.icon = null;
  }

  generatePriceButton(app) {
    let icon,
      textId,
      text = null;
    if (app.isBookmark) {
      if (app.state.pinned) {
        icon = 'tick';
      } else {
        textId = 'pin';
      }
    } else {
      if (app.isInstalled && app.state.updatable) {
        textId = 'update';
      } else if (app.isInstalled) {
        icon = 'tick';
      } else if (app.state.downloading) {
        icon = 'download';
      } else if (!app.purchased && app.info.paid && !app.info.unitPrice) {
        // show cancel icon when payment is not available
        icon = 'cancel';
      } else {
        textId = app.purchased ? 'get' : app.info.paid ? 'price' : 'free';
      }
    }

    this.icon = icon;

    let extraAttrs = {};
    if (icon) {
      extraAttrs['data-icon'] = icon;
    } else {
      if (app.purchased) {
        text = _(textId);
      } else {
        text = app.info.paid ? app.info.unitPrice : _(textId);
      }
    }

    return (
      <div
        className="ads-button"
        data-status={icon ? icon : textId}
        data-pinable={deviceUtils.featureset.bookmarkDBSupported}
        {...extraAttrs}
      >
        {text}
      </div>
    );
  }

  componentDidMount() {
    if (this.element) {
      // Remove the default icon if the real icon loaded successfully.
      const iconDiv = this.element.querySelector('.icon-wrapper');
      const iconImg = iconDiv.querySelector('img');

      iconImg.onload = () => {
        iconDiv.classList.remove('default');
      };
      // Handle progress bar
      this.mountProgressBar();
      requestAnimationFrame(() => {
        this.setTitleOverflow();
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { app, appOrder } = this.props;
    const { pinned } = app.state;
    const { downloading } = nextProps.app.state;
    const { purchased, isInstalled } = nextProps.app;
    const { titleOverflow, showProgressBar } = nextState;
    const { isActivateAsPage, mozAPP } = nextProps;

    if (app.isBookmark) {
      // update elements when pin or unpin the bookmark
      if (pinned !== nextProps.app.state.pinned) {
        return true;
      }
    } else {
      // update app's status which is purchased.
      if (purchased !== this.props.app.purchased) {
        return true;
      }
      // update elements when download started or download complete.
      if (
        (!this.state.showProgressBar && downloading) ||
        (this.state.showProgressBar && !downloading)
      ) {
        return true;
      }

      // If we get mozAPP, then update the elements.
      if (mozAPP) {
        return true;
      }

      if (isInstalled !== this.props.app.isInstalled) {
        return true;
      }
    }

    if (this.props.isActivateAsPage === false && isActivateAsPage === true) {
      return true;
    }

    if (this.state.titleOverflow !== titleOverflow) {
      return true;
    }

    if (this.state.showProgressBar !== showProgressBar) {
      return true;
    }

    if (appOrder !== nextProps.appOrder) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps) {
    const progressBarStateChanged =
      this.state.showProgressBar !== this.props.app.state.downloading;
    const mozAPPLateBound = !prevProps.mozAPP && this.props.mozAPP;
    if (progressBarStateChanged || mozAPPLateBound) {
      this.mountProgressBar();
    }

    if (this.state.progressState === 100) {
      this.switchProgress(false);
    }

    if (
      this.props.mozAPP &&
      prevProps.mozAPP &&
      this.props.app.state.downloading &&
      this.props.mozAPP.progress !== prevProps.mozAPP.progress
    ) {
      this.monitorProgress();
    }
  }

  mountProgressBar() {
    const { app } = this.props;
    if (app.isBookmark) {
      // do something for bookmark without mount progress bar.
    } else {
      this.setupProgress();
    }
  }

  setupProgress() {
    const { downloading } = this.props.app.state;
    // If app is downloading and never start to monitor progress, then do it.
    if (downloading) {
      this.monitorProgress();
    } else {
      this.stopMonitorProgress();
    }
  }

  monitorProgress() {
    const { mozAPP } = this.props;
    if (mozAPP) {
      if (mozAPP.downloadSize === 0) {
        this.progressAutoPlay(true);
        return;
      }
      // reset autoplay
      this.progressAutoPlay(false);

      const progress = Math.floor(
        (this.props.mozAPP.progress / this.props.mozAPP.downloadSize) * 100
      );
      const implicitProgressLevel =
        this.props.mozAPP.progress > 0 && progress < PROGRESS_BASE_LEVEL;
      this.setState({
        progressState: implicitProgressLevel ? PROGRESS_BASE_LEVEL : progress,
      });
    } else {
      // If it is a hosted APP, then mozAPP will be null.
      this.progressAutoPlay(true);
    }
  }

  stopMonitorProgress() {
    const { app, mozAPP } = this.props;
    const { updatable } = app.state;
    const { showProgressBar } = this.state;

    const downloadFailed = !mozAPP;
    const updateFailed = showProgressBar && updatable;
    const downloadStopped = mozAPP && !mozAPP.manifest;
    const downloadSuccess = showProgressBar && app.isInstalled;

    if (downloadFailed || updateFailed || downloadStopped) {
      this.switchProgress(false);
      return;
    }

    if (downloadSuccess) {
      this.setState({
        progressState: 100,
      });
    }
  }

  progressAutoPlay(enable) {
    this.setState({ showProgressBar: true, progressInfinite: enable });
  }

  switchProgress(enable) {
    this.setState({ showProgressBar: enable });
    if (enable === false) {
      // reset to zero.
      this.setState({ progressState: 0 });
    }
  }

  generateIcon(app) {
    const { info } = app;
    let iconClasses = ['icon-wrapper'];
    let iconElement, icon;

    // Set the default icon
    iconClasses.push('default');

    if (app.isBookmark) {
      iconClasses.push('bookmark-icon');
    }

    iconElement = (
      <div className={iconClasses.join(' ')}>
        <img className="app-icon" src={info.icon} alt="" />
      </div>
    );

    return iconElement;
  }

  getAppPopularityInfo(app) {
    const inAppPage = this.props.isActivateAsPage;
    const totalLikes = '2.3K'; // use real number when it's available
    let popularityInfoElement, iconClass;

    if (app.isBookmark) {
      return;
    }

    if (app.state.liked) {
      iconClass = 'app-liked-icon';
    } else if (inAppPage) {
      iconClass = 'app-unliked-icon-detail-page';
    } else {
      iconClass = 'app-unliked-icon-list-page';
    }

    popularityInfoElement = (
      <div className="app-popularity-wrapper">
        <div className={iconClass} />
        <span>{totalLikes}</span>
      </div>
    );

    return popularityInfoElement;
  }

  setTitleOverflow() {
    if (isOverflown(this.infoWrapper) && this.state.titleOverflow === false) {
      this.setState({
        titleOverflow: true,
      });
    }
  }

  onFocus = () => {
    this.setState({ isFocused: true });
    this.setTitleOverflow();
    if (this.props.focusHandler) {
      this.props.focusHandler();
    }
  };

  // XXX: create this new method which is only used by AppListView in AppsPanel
  // to not affect other components that are using AppBrick
  handleFocus = async navDirection => {
    this.setTitleOverflow();
    this.element.focus();
    this.element.scrollIntoView({
      // set vertical alignment to bottom when moving upwards and vice versa
      // to ensure the component could be shown completely
      block: navDirection > 0 ? 'end' : 'start',
    });
    if (RichContentHelper.enable) {
      await loadImage(this.element, 'bg');
    }
  };

  onBlur = () => {
    this.setState({ isFocused: false });
    if (!this.props.isActivateAsPage) {
      this.setState({ titleOverflow: false });
    }
  };

  render() {
    const {
      app,
      isActivateAsPage,
      isLoadBgWhenFocused,
      hasProgressBar,
      core,
    } = this.props;
    const { titleOverflow, showProgressBar, isFocused } = this.state;
    const { bg, localized, version } = app.info;
    const { name, subtitle } = localized;

    let priceButton = this.generatePriceButton(app);
    let iconElement = this.generateIcon(app);
    // let popularityElement = this.getAppPopularityInfo(app); // enable it when the backend is ready.
    const style = {
      backgroundImage:
        (bg && isActivateAsPage) || (bg && isLoadBgWhenFocused && isFocused)
          ? `url(${bg})`
          : null,
    };
    return (
      <div
        className={`brick ${
          (isActivateAsPage && bg) || (isLoadBgWhenFocused && isFocused && bg)
            ? 'bg-loaded'
            : ''
        }`}
        tabIndex="1"
        data-id={app.id}
        data-bg-image={bg}
        data-type={app.type}
        data-version={version}
        data-app-order={this.props.appOrder}
        ref={element => {
          this.element = element;
        }}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        style={style}
      >
        <div className="info-box">
          {iconElement}
          <section
            className={'info ' + (isActivateAsPage ? '' : 'full')}
            ref={element => {
              this.infoWrapper = element;
            }}
          >
            {titleOverflow ? (
              <Marquee>
                <h2>{name}</h2>
              </Marquee>
            ) : (
              <h2>{name}</h2>
            )}
            {/* enable it when the backend is ready. */}
            {/* {popularityElement} */}
            {(!hasProgressBar || !app.state.downloading) &&
              !isActivateAsPage && (
                <p>{app.isBookmark ? _('website') : subtitle}</p>
              )}
            {hasProgressBar && (
              <div
                className={'progress-box ' + (showProgressBar ? '' : 'hide')}
              >
                <ProgressBar
                  infinite={this.state.progressInfinite}
                  percentage={this.state.progressState}
                />
              </div>
            )}
            {core &&
              !hasProgressBar && <div className="size">{app.info.size}</div>}
          </section>
        </div>
        {!core && priceButton}
      </div>
    );
  }
}

export default AppBrick;
