import { MessageSender } from 'web-message-helper';
import { PaymentDetail } from 'kaistore-post-messenger/src/models';
import { RequestPaymentCommand } from 'kaistore-post-messenger/src/commands';
import { fetchThroughClient } from './http-request-helper';

/** A class to handle payment related operations.
 */
class PaymentHelper {
  /**
   * Initializes variables and listeners.
   */
  constructor() {
    this._apiPrefix = window.deviceInfos.get('apps.serviceCenterUrl');
    this.noProvider = false;

    // Change the value at all paymentHelper's instances.
    window.addEventListener('kaypay:no-provider', () => {
      this.noProvider = true;
    });
  }

  /**
   * Gets all purchased items of current device/account.
   * @return {Promise} Response of the request.
   */
  getAllPurchased() {
    const url = `${this._apiPrefix}/products?purchased=true`;
    return fetchThroughClient(url);
  }

  /**
   * Gets purchased item with productId of current device/account.
   * @param {String} productId - Id of the product.
   * @return {Promise} Response of the request. 404 will be returned if
   * device/account has never purchased the item.
   * @private
   */
  _getPurchasedByProductId(productId) {
    const url = `${this._apiPrefix}/products/${productId}?purchased=true`;
    return fetchThroughClient(url);
  }

  /**
   * @typedef ReceiptToken
   * @property {String} receipt_token - JWT of the Receipt.
   */

  /**
   * Gets ReceiptToken object with transactionId.
   * @param {String} transactionId - Id of the transaction.
   * @return {Promise} {@link ReceiptToken} will be resolved upon successful
   * response.
   */
  getReceiptsByTransactionId(transactionId) {
    const url = `${this._apiPrefix}/transactions/${transactionId}/receipt`;
    return fetchThroughClient(url);
  }

  /**
   * @typedef PaymentRequest
   * @property {String} id - Product id.
   * @property {String} name - Product name.
   */

  /**
   * Requests payment by sending message to client.
   * @param {PaymentRequest} detail - Payment detail.
   * @return {Promise} {@link ReceiptToken} will be resolved upon successful
   * activity.
   */
  pay(detail) {
    return new Promise((resolve, reject) => {
      const { id, name } = detail;
      const command = new RequestPaymentCommand({
        detail: new PaymentDetail({
          id,
          name,
        }),
      });
      MessageSender.send(command, (success, cbDetail) => {
        const { receipt, error } = cbDetail;
        if (success) {
          resolve(receipt);
        } else {
          reject(error);
        }
      });
    });
  }

  /**
   * @typedef Product
   * @property {String} id - Product id.
   * @property {String} pid - Id of partner which created the Product
   * @property {0|1|2} type - Product type. 0 - Generic virtual good. 1 - Paid-App
   * . 2 - In-App Good.
   * @property {String} name - Product name.
   * @property {String} related_good_id - For Paid Apps this would be the App ID.
   * @property {String} transaction_id - Transaction Id.
   * @property {Integer} qty - Product quantity.
   * @property {Date} created_date - Date of the creation of the Product.
   */

  /**
   * Checks if the product has been purchased by calling
   * getPurchasedByProductId()with format checking on result.
   * @param {String} productId - Product id.
   * @return {Promise} {@link Product} will be resolved upon successful response.
   */
  isProductPurchased(productId) {
    return this._getPurchasedByProductId(productId).then(product => {
      if (product && product.transaction_id) {
        return product;
      }
      throw new Error('No transaction_id found');
    });
  }
}
export default PaymentHelper;
